export const validateEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const containsNumbers = (str: string) => {
  const regex = /\d/;
  return regex.test(str);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const regex = /^([+\d\s()-]{1,20})(,\s*[+\d\s()-]{1,20})*$/;
  const noLeadingTrailingSpacesRegex = /^\S.*\S$/; // No leading or trailing spaces
  return regex.test(phoneNumber) && noLeadingTrailingSpacesRegex.test(phoneNumber);
};
