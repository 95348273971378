import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";

const chartOptions = {
  animation: false,
  grid: {
    top: 10,
    left: 10,
    right: 10,
  },
  xAxis: {
    data: [],
    axisLabel: {
      show: true, // Ensure the x-axis labels are shown
      color: "#000", // Set the color of the labels
      fontSize: 12, // Set the font size of the labels
    },
  },
  yAxis: {
    show: false,
  },
  series: [
    {
      name: "Library",
      type: "bar",
      barWidth: "50%",
      data: [],
    },
  ],
};

export default function Charts({ data }: Readonly<{ data: any }>) {
  const chartRef = useRef(null);
  const [options, setOptions] = useState<any>(chartOptions);

  useEffect(() => {
    const xAxisData = Object.keys(data);
    const seriesData = Object.values(data).map((value) => ({
      value,
      itemStyle: {
        color: "#0A6E82",
      },
      label: {
        show: true, // Show label inside the bar
        position: "inside",
        valueAnimation: false,
        formatter: "{c}", // Display the value
        color: "#fff", // Label color for better contrast
        fontSize: 12, // Adjust font size for better visibility
      },
    }));

    const updatedOptions = {
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        data: xAxisData,
      },
      series: [
        {
          ...chartOptions.series[0],
          data: seriesData,
        },
      ],
    };

    setOptions(updatedOptions);
  }, [data]);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current, null, {
      renderer: "svg",
      width: "500%",
    });

    chartInstance.setOption(options);

    // Cleanup on component unmount
    return () => {
      chartInstance.dispose();
    };
  }, [options]);

  return <div ref={chartRef} style={{ height: "129px", width: "100%" }} />;
}
