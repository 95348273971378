import { useContext, useEffect, useReducer, useState } from "react";
import { toastError } from "../../../components/common/toast";
import useSite from "../../../hooks/useSite";
import { getSingleSiteConfiguration } from "../../../services/SiteConfiguration";
import { Box } from "@maersk-global/community-react";
import { McButton, McTable } from "@maersk-global/mds-react-wrapper";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../../reducers/delete";
import { loadingIndicator } from "../../../components/common/loading";
import Notifications from "../../../components/common/notifications";
import Welcome from "../../Welcome";
import Heading from "../../../components/common/heading";
import { Button } from "../../../components/common/button";
import DeleteModal from "../../../components/common/deleteModal";
import {
  EmergencyContactsKeys,
  EmergencyContactsModel,
} from "../../../models/EmergencyContacts";
import AddEditEmergencyContact from "./modals/addEmergencyContact";
import { getEmergencyContact } from "../../../services/Site/EmergencyContact";
import { Cards } from "../../../components/common/cards";
import { configuration } from "../../../constants/Site";
import SiteElementsRenderer from "../../../components/common/siteElementsRenderer";
import { ModeContext } from "../SiteBCM";
import { SiteModes } from "../../../constants/SiteBCM";
import HorizontalLine from "../../../components/common/horizontalLine";
import Action from "../../../components/common/action";
import { chunkArray } from "../../../utils/utils";
import { ConfigurationMapping } from "../AlternativeLocation/modals/LocationModal.types";

export default function EmergencyContacts({
  handleStatusChange,
}: Readonly<{ handleStatusChange: () => void }>) {
  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [isApplicable, setIsApplicable] = useState<boolean>(true);
  const [configurations, setConfigurations] = useState<
    ConfigurationMapping | undefined
  >(undefined);
  const [EmergencyContacts, setEmergencyContacts] = useState<any[]>([]);
  const [EmergencyContactstableData, setEmergencyContactsTableData] = useState<
    any[]
  >([]);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editableEmergencyContact, setEditableEmergencyContact] = useState<
    any | null
  >(null);
  const [showtable, setShowTable] = useState<boolean>(false);

  const SetShowTable = () => {
    setShowTable(!showtable);
  };

  const getEmergencyContacts = () => {
    getEmergencyContact(site.id, site.versionId)
      .then((response) => {
        if (response.length > 0) {
          setEmergencyContactsTableData(response);
          let chunkedArray = chunkArray(response, 2);
          setEmergencyContacts(chunkedArray);
        } else {
          setEmergencyContacts([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const onModalClose = () => {
    setEditableEmergencyContact(null);
    setIsModalOpen(false);
  };

  const onSave = () => {
    getEmergencyContacts();
    handleStatusChange();
  };

  const externalContactsKey: EmergencyContactsKeys = {
    serviceName: "emergency-contact-services",
    phoneNumber: "phone",
  };

  const handleDelete = (id: number, path: string) => {
    onDeleteEntity(
      dispatchDelete,
      id,
      "EmergencyContact",
      path,
      "emergencycontacts",
      ""
    );
  };

  useEffect(() => {
    if (siteId > 0) {
      getSingleSiteConfiguration(site.id, site.versionId, "emergency-contacts")
        .then((response) => {
          if (response.length === 0) {
            setIsApplicable(false);
            setLoading(false);
            return;
          } else {
            let mappedResponse: ConfigurationMapping = response.reduce(
              (acc, item) => {
                return {
                  ...acc,
                  [item.key]: item.configurationValue,
                };
              },
              {}
            );
            setConfigurations(mappedResponse);
            getEmergencyContacts();
          }
        })
        .catch((error) => {
          toastError(error.message);
        });
    }
  }, [siteId]);

  const onAddNewEmergencyContactClick = () => {
    setIsModalOpen(true);
  };

  const constructActionItems = (row: any) => {
    let items = [];

    mode === SiteModes.Write &&
      items.push({
        label: "Edit",
        onClick: () => {
          setIsModalOpen(true);
          setEditableEmergencyContact(row);
        },
      });

    mode === SiteModes.Write &&
      !row.isConfiguredArchetypeLevel &&
      items.push({
        label: "Delete",
        onClick: () => handleDelete(row.id, row.serviceName),
      });

    return items;
  };

  const displayTable = () => {
    return (
      <McTable
        disableroundedcorners
        data={EmergencyContactstableData}
        expand
        columns={[
          { id: "serviceName", label: "Description" },
          { id: "phoneNumber", label: "Phone" },

          { id: "id", label: "", sortDisabled: true, align: "right" },
        ]}
        fit="small"
      >
        {EmergencyContactstableData.map((row: any) => (
          <div key={`${row.id}_id`} slot={`${row.id}_id`}>
            <div
              style={{
                float: "right",
              }}
            >
              {mode === SiteModes.Write ? (
                <Action items={constructActionItems(row)} />
              ) : null}
            </div>
          </div>
        ))}
      </McTable>
    );
  };

  const displayasCard = () => {
    return (
      <>
        {EmergencyContacts.map((chunk: any, chunkIndex: number) => (
          <Box
            key={chunkIndex}
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap="10px"
          >
            {chunk.map((emergencyContact: EmergencyContactsModel) => (
              <Box
                key={emergencyContact.id}
                style={{
                  padding: "10px",
                  border:
                    "1px solid var(--mds_brand_appearance_neutral_weak_border-color)",
                  boxSizing: "border-box",
                  height: "auto",
                  overflow: "auto",
                  margin: "8px",
                }}
              >
                <Cards
                  items={[{ label: "", value: emergencyContact.phoneNumber }]}
                  key={emergencyContact.id}
                  id={emergencyContact.id}
                  title={emergencyContact.serviceName}
                  allowEdit={mode === SiteModes.Write}
                  displayWarning={
                    emergencyContact.isConfiguredArchetypeLevel &&
                    !emergencyContact.isContactDetailsFilled &&
                    emergencyContact.configurationValue ===
                      configuration.Mandatory
                  }
                  allowDelete={
                    mode === SiteModes.Write &&
                    !emergencyContact.isConfiguredArchetypeLevel
                  }
                  onDelete={handleDelete}
                  onEdit={() => {
                    setIsModalOpen(true);
                    setEditableEmergencyContact(emergencyContact);
                  }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </>
    );
  };

  const renderWelcome = () => {
    return (
      <>
        <Welcome
          heading={"Emergency Contacts"}
          subHeading={
            "There’s no emergency contacts for the site. Start adding one."
          }
          buttonLabel={"Add New Emergency Contact"}
          icon="plus"
          onButtonClick={onAddNewEmergencyContactClick}
          isDisabled={mode === SiteModes.Read}
        />
      </>
    );
  };

  const renderExternalConatcs = () => {
    return (
      <>
        <SiteElementsRenderer mode={mode}>
          <>
            <Heading heading={""} subheading={""}>
              <Button
                click={onAddNewEmergencyContactClick}
                label="Add New Emergency Contact"
                fit="medium"
              />
            </Heading>
            <HorizontalLine />
          </>
        </SiteElementsRenderer>
        <Heading heading={""} subheading={""}>
          <McButton
            label={showtable ? "Show as Cards" : "Show as Table"}
            appearance="neutral"
            variant="outlined"
            fit="small"
            click={SetShowTable}
          ></McButton>
        </Heading>
        <br />
        {!showtable ? displayasCard() : displayTable()}
      </>
    );
  };

  if (loading) return loadingIndicator;

  // IF NOT APPLICABLE SHOW WARNING
  if (!isApplicable) {
    return (
      <Notifications
        description={["This section is not applicable for your site."]}
        variant="warning"
      />
    );
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onSave}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
          handleStatusChange();
        }}
      />
      <AddEditEmergencyContact
        isOpen={isModalOpen}
        editableEmergencyContact={editableEmergencyContact}
        configurations={configurations}
        keys={externalContactsKey}
        onClose={onModalClose}
        onSave={onSave}
      />
      {EmergencyContacts.length == 0
        ? renderWelcome()
        : renderExternalConatcs()}
    </>
  );
}
