import {
  BrowserCacheLocation,
  Configuration,
  EventMessage,
  EventMessageUtils,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { accessTokenStrategy } from '../constants/App';

export const localStorageKeys = {
  idTokenKey: `msal.token.keys.${(window as any).config.azureAd.clientId}`,
  storageType: 'localStorage',
};

export const msalConfig: Configuration = {
  auth: {
    authority: (window as any).config.azureAd.authority,
    clientId: (window as any).config.azureAd.clientId,
    postLogoutRedirectUri: (window as any).config.azureAd.redirectUri,
    redirectUri: (window as any).config.azureAd.redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((message: EventMessage) => {
  const status = EventMessageUtils.getInteractionStatusFromEvent(message);
  if (status) {
    localStorage.setItem('msalStatus', status);
  }
});

export const signOut = async () => {
  await msalInstance.logoutRedirect();
};

export const getUserInfo = () => {
  try {
    const tokenKey: any = localStorage.getItem(localStorageKeys?.idTokenKey);
    const idTokenKey: any = JSON.parse(tokenKey)?.idToken[0];
    const tokenObject: any = localStorage.getItem(idTokenKey);
    const token = JSON.parse(tokenObject)?.secret;

    const userDetails = parseJwt(token);

    return {
      roles: userDetails?.roles,
      userName: `${userDetails?.name}`,
      email: `${userDetails?.preferred_username}`,
    };
  } catch (err) {
    // User is required to interact with the server to provide credentials or consent
    return false;
  }
};

export const getAccessTokenForStrategy = async (strategy: string) => {
  if (strategy === accessTokenStrategy.DEFAULT) {
    return getAccessToken();
  } else if (strategy === accessTokenStrategy.NOTIFICATIONS) {
    return getAccessTokenForNotification();
  }
};

export const getAccessToken = async () => {
  await msalInstance.initialize();
  await msalInstance.handleRedirectPromise();
  const consumerKey = (window as any).config.consumerKey;

  const accessTokenRequest: SilentRequest = {
    account: msalInstance.getAllAccounts()[0],
    scopes: [(window as any).config.azureAd.scope],
  };

  try {
    const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);

    const payload = parseJwt(accessTokenResponse.idToken);
    const expiryTimestamp = payload.exp;
    const expiryDate = new Date(expiryTimestamp * 1000);

    if (expiryDate < new Date()) {
      throw new Error('Token is expired');
    }
    let token = accessTokenResponse.idToken;
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ token, consumerKey });
    }

    return accessTokenResponse.idToken;
  } catch (err) {
    console.log('Failed to silently acquire token', err);

    if (
      (err as any) instanceof InteractionRequiredAuthError ||
      (err as any).message === 'Token is expired'
    ) {
      // Interactive request to refresh the token
      await msalInstance.acquireTokenRedirect(accessTokenRequest);
    } else {
      await signOut();
    }
  }
};

//TODO: SSS -> How to handle different scopes???
export const getAccessTokenForNotification = async () => {
  const silentRequest: SilentRequest = {
    scopes: (window as any).config.azureAd.notificationsScope,
    account: msalInstance.getAllAccounts()[0],
  };
  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (error) {
    console.error('Token exchange failed: ', error);
  }
};

const parseJwt = (token: any) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};
