import { McInput, McTextarea } from '@maersk-global/mds-react-wrapper';
import Box from '../../../../components/common/box';
import { AlternativeLocationModel } from './LocationModal.types';
import { FC } from 'react';

interface Props {
  externalLocation: AlternativeLocationModel;
  setExternalLocation: (externalLocation: AlternativeLocationModel) => void;
}
const ExternalLocationContent: FC<Props> = ({ externalLocation, setExternalLocation }) => {
  return (
    <Box display='flex' flexDirection='column'>
      <McInput
        type='text'
        label='Site Name *'
        required={true}
        value={externalLocation.externalSiteName}
        input={(e: InputEvent) => {
          const target = e.target as HTMLInputElement;
          setExternalLocation({
            ...externalLocation,
            externalSiteName: target.value,
          });
        }}
        name='externalSiteName'
      ></McInput>
      <McTextarea
        label='Remark *'
        required={true}
        style={{ marginTop: '1rem' }}
        placeholder='Enter details about the external location here..'
        value={externalLocation.externalSiteRemarks}
        rows={5}
        name='externalSiteRemarks'
        input={(e: InputEvent) => {
          const target = e.target as HTMLTextAreaElement;
          setExternalLocation({
            ...externalLocation,
            externalSiteRemarks: target.value,
          });
        }}
      ></McTextarea>
    </Box>
  );
};
export default ExternalLocationContent;
