import { ConcreteRoles, Roles } from '../constants/Roles';

//TODO: SSS -> There are 2 things happening in this. Roles are populated and from the permissions and then the concrete roles are populated from the roles.
//Fix by directly returning concrete roles from the permissions and using them across the application.
export const buildRolesFromTokenRoles = (roles: Array<string>): string[] => {
  if (roles.includes(Roles.GSCADMIN)) {
    return [Roles.GSCADMIN];
  } else if (roles.includes(Roles.PROCESSOWNER)) {
    return [Roles.PROCESSOWNER];
  } else if (roles.includes(Roles.SITECOORDINATOR) && !roles.includes(Roles.SITEOWNER)) {
    return [Roles.SITECOORDINATOR];
  } else if (roles.includes(Roles.SITEOWNER)) {
    return [Roles.SITEOWNER];
  } else if (roles.includes(Roles.BRADMIN)) {
    return [Roles.BRADMIN];
  } else if (roles.includes(Roles.SITEUSER)) {
    return [Roles.SITEUSER];
  } else if (roles.includes(Roles.OFFICEUSER)) {
    return [Roles.OFFICEUSER];
  } else if (roles.includes(Roles.SNRADMIN)) {
    return [Roles.SNRADMIN];
  }

  return [];
};

export const buildConcreteRolesFromTokenRoles = (roles: Array<string>): string => {
  if (roles.includes(Roles.GSCADMIN)) {
    return ConcreteRoles.GSCADMIN;
  } else if (roles.includes(Roles.PROCESSOWNER)) {
    return ConcreteRoles.PROCESSOWNER;
  } else if (roles.includes(Roles.SITECOORDINATOR)) {
    return ConcreteRoles.SITECOORDINATOR;
  } else if (roles.includes(Roles.SITEOWNER)) {
    return ConcreteRoles.SITEOWNER;
  } else if (roles.includes(Roles.SITEUSER)) {
    return ConcreteRoles.SITEUSER;
  } else if (roles.includes(Roles.BRADMIN)) {
    return ConcreteRoles.BRADMIN;
  } else if (roles.includes(Roles.OFFICEUSER)) {
    return ConcreteRoles.OFFICEUSER;
  } else if (roles.includes(Roles.SNRADMIN)) {
    return ConcreteRoles.SNRADMIN;
  }
  return '';
};
