import Heading from "../../../components/common/heading";
import { McButton, McTable, McTag } from "@maersk-global/mds-react-wrapper";
import SiteElementLayout from "../../../components/common/siteElementLayout";
import SiteFilter from "./SiteFilter";
import { useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../../components/common/toast";
import CustomisableTableHeader from "./CustomisableTableHeader";
import CustomizableColumnsModal from "./modals/customizableColumnsModal";
import Pagination from "../../../components/common/pagination";
import { columns } from "../../Contacts/Contact";
import { loadingIndicator } from "../../../components/common/loading";
import AdditionalLinkText from "../../../components/common/additionalLinkText";
import { useNavigate } from "react-router-dom";
import { siteBCPStatus } from "../../../constants/Site";
import {
  getFilter,
  getFilteredSites,
  saveCustomizedColumns,
  saveFilter,
} from "../../../services/Site/CustomisedDashboard";
import { formatDate } from "../../../utils/utils";

export default function Dashboard() {
  const navigate = useNavigate();

  const [currentFilter, setCurrentFilter] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableColumns, setTableColumns] = useState<any>([]);

  const [availableColumnsList, setAvailableColumnsList] = useState<any[]>([]);
  const [sortableColumnsList, setSortableColumnsList] = useState<any[]>([]);

  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [filteredSites, setFilteredSites] = useState<any>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getFilter()
      .then((response) => {
        setCurrentFilter(response.savedDashboardFilter);
        buildTableColumnsModal(
          response.availableTableColumns,
          response.selectedTableColumns
        );
      })
      .catch((error) => {
        toastError(error);
      });
  }, []);

  useEffect(() => {
    let updatableTableColumns = sortableColumnsList
      .filter((column: any) => column.isChecked)
      .map((column: any) => ({
        label: column.label,
        id: column.key,
        sticky: column.key === "siteCode" ? "left" : "",
      }));

    setTableColumns(updatableTableColumns);
  }, [sortableColumnsList]);

  useEffect(() => {
    if (currentFilter != null)
      getAllFilteredSites(currentFilter, pageSize, currentPage);
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (currentFilter != null)
      getAllFilteredSites(currentFilter, pageSize, currentPage);
  }, [currentFilter]);

  useEffect(() => {
    ///call the api and get the data
  }, [columns]);

  const buildTableColumnsModal = (
    availableColumnsList: any,
    sortableColumnsList: any
  ) => {
    availableColumnsList = availableColumnsList.map(
      (column: any, index: number) => ({
        id: index,
        isChecked: false,
        ...column,
      })
    );

    sortableColumnsList = sortableColumnsList.map(
      (column: any, index: number) => ({
        id: index,
        isChecked: true,
        ...column,
      })
    );
    sortableColumnsList.forEach((column: any) => {
      let index = availableColumnsList.findIndex(
        (item: any) => item.key === column.key
      );
      if (index > -1) {
        availableColumnsList[index].isChecked = true;
      }
    });
    setAvailableColumnsList(availableColumnsList);
    setSortableColumnsList(sortableColumnsList);
  };

  const onFilterChange = (query: string) => {
    setCurrentPage(1);
    setCurrentFilter(query);
  };

  const getAllFilteredSites = (
    query: string,
    pageSize: number,
    currentPage: number
  ) => {
    getFilteredSites(pageSize, currentPage, query)
      .then((response) => {
        setFilteredSites(response.sites);
        setTotalRows(response.totalCount);
      })
      .catch((error) => {
        toastError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveDashboardFilter = () => {
    if (currentFilter != null) {
      saveFilter(currentFilter)
        .then((response) => {
          if (response) {
            toastSuccess("Default Filter set successfully");
          }
        })
        .catch((error) => {
          toastError(error.message);
        });
    }
  };

  const onCustomizeClick = () => {
    setIsModalOpen(true);
  };

  const onChangesApplied = (sortableColumns: any, selectableColumns: any) => {
    let modifiedSortableColumns = sortableColumns
      .filter((column: any) => column.isChecked)
      .map((column: any) => ({
        key: column.key,
        isDefault: column.isDefault,
        label: column.label,
      }));

    saveCustomizedColumns(modifiedSortableColumns)
      .then((response) => {
        setAvailableColumnsList(selectableColumns);
        setSortableColumnsList(sortableColumns);

        setIsModalOpen(!isModalOpen);
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const onDefaulColumnsRestored = () => {
    let availableColumnsListItems = availableColumnsList.map((column: any) => ({
      key: column.key,
      isDefault: column.isDefault,
      label: column.label,
      isChecked: column.isDefault,
      id: column.id,
    }));

    let sortableColumnsListItems = sortableColumnsList.map((column: any) => ({
      key: column.key,
      isDefault: column.isDefault,
      label: column.label,
      isChecked: column.isDefault,
      id: column.id,
    }));

    setAvailableColumnsList(availableColumnsListItems);
    setSortableColumnsList(sortableColumnsListItems);
    setIsModalOpen(false);

    saveCustomizedColumns("")
      .then((response) => {
        toastSuccess("Default columns restored successfully");
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  //TODO: SSS -> This piece of code is duplicated from siteBCM> Refactor this.
  const getSiteBcpStatusTag = (status: string) => {
    switch (status) {
      case siteBCPStatus.NotStarted:
        return (
          <McTag label="Not Started" fit="small" appearance="neutral-inverse" />
        );
      case siteBCPStatus.InProgress:
        return (
          <McTag label="Inprogress" fit="small" appearance="neutral-weak" />
        );
      case siteBCPStatus.PendingApproval:
        return <McTag label="Pending Approval" fit="small" appearance="info" />;
      case siteBCPStatus.Approved:
        return <McTag label="Approved" fit="small" appearance="success" />;
      case siteBCPStatus.Validated:
        return <McTag label="Validated" fit="small" appearance="success" />;
      case siteBCPStatus.ReworkInProgress:
        return (
          <McTag label="Rework In Progress" fit="small" appearance="warning" />
        );
      case siteBCPStatus.Rejected:
        return <McTag label="Rejected" fit="small" appearance="error" />;
      default:
        return <></>;
    }
  };

  if (loading) return loadingIndicator;

  return (
    <>
      <CustomizableColumnsModal
        isOpen={isModalOpen}
        availableColumns={availableColumnsList}
        sortedColumns={sortableColumnsList}
        onChangesApplied={onChangesApplied}
        onModalClosed={() => setIsModalOpen(false)}
        onDefaultColumnsRestored={onDefaulColumnsRestored}
      />
      <Heading heading="Site Overview" subheading={""}>
        <McButton
          fit="medium"
          variant="outlined"
          appearance="neutral"
          label="Set filters as default"
          icon="floppy-disk"
          click={saveDashboardFilter}
        />
      </Heading>
      <SiteElementLayout heading={""} shouldShowComments={false}>
        {currentFilter != null && (
          <SiteFilter onChange={onFilterChange} currentFilter={currentFilter} />
        )}
      </SiteElementLayout>
      <SiteElementLayout heading={""} shouldShowComments={false}>
        <CustomisableTableHeader
          onCustomiseClick={onCustomizeClick}
          onRowsChange={(rows: number) => {
            setCurrentPage(1);
            setPageSize(rows);
          }}
        />
        <McTable
          columns={tableColumns}
          data={filteredSites}
          datakey="siteId"
          fit="medium"
        >
          {filteredSites.map((row: any) => (
            <>
              <div
                key={`${row.siteId}_siteCode`}
                slot={`${row.siteId}_siteCode`}
              >
                <div style={{ textDecoration: "none", fontWeight: "normal" }}>
                  <AdditionalLinkText
                    additionalText={row.siteCode}
                    id={row.siteId}
                    onAdditionalTextClick={(id) => {
                      navigate(`/business-continuity-management/${id}`);
                    }}
                  />
                </div>
              </div>
              <div
                key={`${row.siteId}_siteStatus`}
                slot={`${row.siteId}_siteStatus`}
              >
                {getSiteBcpStatusTag(row.siteStatus)}
              </div>
              <div
                key={`${row.siteId}_approvedAt`}
                slot={`${row.siteId}_approvedAt`}
              >
                {row.approvedAt ? formatDate(row.approvedAt) : ""}
              </div>
            </>
          ))}
        </McTable>
        <Pagination
          pageSize={pageSize}
          currentpage={currentPage}
          onPagechange={(page: number) => setCurrentPage(page)}
          itemsCount={totalRows}
        />
      </SiteElementLayout>
    </>
  );
}
