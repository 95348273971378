import SiteElementLayout from "../../components/common/siteElementLayout";
import {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Button } from "../../components/common/button";
import Box from "../../components/common/box";
import { McFileUpload } from "@maersk-global/mds-react-wrapper/components-core/mc-file-upload";
import {
  McButton,
  McCard,
  McNotification,
  McPopover,
} from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";
import { DeleteIcon, StaticIcon } from "../../components/common/icons";
import {
  downloadFile,
  getLocationLayout,
  saveLocationLayout,
} from "../../services/Site/SiteDetails";
import { toast } from "../../components/common/toast";
import { loadingIndicator } from "../../components/common/loading";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../reducers/delete";
import DeleteModal from "../../components/common/deleteModal";
import defaultImageThumbnail from "../../assets/defaultImageThumbnail.png";
import failedThumbnail from "../../assets/failedThumbnail.png";
import { getApiUrl } from "../../utils/application-utils";
import Notifications from "../../components/common/notifications";
import useSite from "../../hooks/useSite";
import SiteElementsRenderer from "../../components/common/siteElementsRenderer";
import { ModeContext } from "./SiteBCM";
import { StyledListItem } from "../../components/common/StyledComponents";

const FileScanningStatus = {
  VotiroRequestCompleted: 1,
  FileSanitisedCompleted: 2,
  ProcessCompleted: 3,
  Processed: 4,
  Blocked: 5,
};

export default function LocationLayout({
  sectionId,
  handleStatusChange,
}: {
  sectionId: number;
  handleStatusChange: () => void;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isApplicable, setIsApplicable] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<FileList>();
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );
  const [isFilesUploaded, setIsFilesUploaded] = useState<boolean>(false);
  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  useEffect(() => {
    if (siteId > 0) {
      getLocationLayout(site.id, site.versionId)
        .then((response) => {
          setIsApplicable(response.isApplicable);
          if (response.isApplicable) {
            setIsFilesUploaded(true);
            setUploadedFiles(response.files);
            handleStatusChange();
          }
          setLoading(false);
        })
        .catch((error) => {
          toast("Error", error.message, "error");
        });
    }
  }, [siteId]);

  useEffect(() => {
    if (!loading && uploadedFiles.length === 0) {
      handleStatusChange();
    }
  }, [uploadedFiles]);

  const getUploadedFiles = () => {
    getLocationLayout(site.id, site.versionId)
      .then((response) => {
        setUploadedFiles(response.files);
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  };

  const handleInputChange = (e: any) => {
    let files = e.target.files;
    if (files.length === 0) {
      setIsFilesUploaded(!isFilesUploaded);
      setSelectedFiles(undefined);
      return;
    }
    setSelectedFiles(files);
  };

  const download = (fileName: string, filepath: string) => {
    downloadFile(site.id, filepath);
  };

  const save = () => {
    if (!selectedFiles) {
      toast("Error", "Please select file(s) to upload", "error");
      return;
    }

    let formData = new FormData();
    formData.append("SiteId", site.id.toString());

    if (selectedFiles) {
      let totalSize = 0;
      for (const element of selectedFiles) {
        formData.append("Files", element);
        totalSize += element.size;
      }
      let sizeInMb = totalSize / (1024 * 1024);

      if (sizeInMb > 10) {
        toast(
          "Error",
          "Total size of uploaded files should not exceed 10MB",
          "error"
        );
        return;
      }
    }

    saveLocationLayout(formData)
      .then((response) => {
        toast("Success", "Files uploaded successfully", "success");
        getUploadedFiles();
        setSelectedFiles(undefined);
        setIsFilesUploaded(!isFilesUploaded);
        handleStatusChange();
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  };

  const onDeleteFile = (file: any) => {
    onDeleteEntity(
      dispatchDelete,
      file.fileId,
      "LocationLayoutFile",
      file.fileName,
      `locationlayout/${site.id}`,
      ""
    );
  };

  const getFileThumbnail = (file: any) => {
    let extension = file.fileName.split(".").pop();
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "bmp":
      case "png":
        return !file.thumnailPath
          ? defaultImageThumbnail
          : `${getApiUrl()}/file/downloadfile/${site.id}?filepath=${
              file.thumnailPath
            }`;
      default:
        return "/assets/images/file.png";
    }
  };

  const getFileStatusText = (fileStatus: any) => {
    switch (fileStatus) {
      case FileScanningStatus.VotiroRequestCompleted:
        return "File scanning in progress.";
      case FileScanningStatus.FileSanitisedCompleted:
        return "File scanning successful.";
      case FileScanningStatus.ProcessCompleted:
      case FileScanningStatus.Processed:
        return "File uploaded successfully.";
      case FileScanningStatus.Blocked:
        return "File scanning failed.";
      default:
        return "";
    }
  };

  const buildUploadedFiles = useCallback(() => {
    return (
      <UploadedFilesWrapper key={site.id}>
        {uploadedFiles.map((file) => {
          return (
            <Box key={file.fileId}>
              <CardWrapper>
                <McCard imagepercent={80} fit="medium">
                  <div slot="footer">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <FileNameContainer>{file.fileName}</FileNameContainer>
                      <div>
                        <McPopover
                          trigger="hover"
                          position="top-left"
                          fit="medium"
                          width="auto"
                        >
                          <StaticIcon
                            icon="info-circle"
                            color={
                              "var(--mds_brand_appearance_warning_default_background-color)"
                            }
                            slot="trigger"
                          />
                          <div style={{ padding: "10px" }}>
                            {getFileStatusText(file.fileStatus)}
                          </div>
                        </McPopover>
                      </div>
                    </div>
                  </div>
                  <span slot="image">
                    <img
                      src={
                        file.fileStatus !== FileScanningStatus.Blocked
                          ? getFileThumbnail(file)
                          : failedThumbnail
                      }
                      alt={file.fileName}
                      height={180}
                      width={180}
                    />
                  </span>
                  <ActionButtonsWrapper slot="actions">
                    <McButton
                      icon="arrow-down"
                      appearance="neutral"
                      variant="filled"
                      hiddenlabel
                      fit="small"
                      click={() => {
                        download(file.fileName, file.filePath);
                      }}
                      disabled={
                        file.fileStatus !== FileScanningStatus.ProcessCompleted
                      }
                    />
                    <SiteElementsRenderer mode={mode}>
                      <McButton
                        appearance="neutral"
                        variant="filled"
                        hiddenlabel
                        fit="small"
                        disabled={
                          !(
                            file.fileStatus ==
                              FileScanningStatus.ProcessCompleted ||
                            file.fileStatus == FileScanningStatus.Blocked
                          )
                        }
                      >
                        <span slot="icon">
                          <DeleteIcon onClick={() => onDeleteFile(file)} />
                        </span>
                      </McButton>
                    </SiteElementsRenderer>
                  </ActionButtonsWrapper>
                </McCard>
              </CardWrapper>
            </Box>
          );
        })}
      </UploadedFilesWrapper>
    );
  }, [uploadedFiles]);

  const locationLayout = useMemo(() => buildUploadedFiles(), [uploadedFiles]);

  if (loading) return loadingIndicator;

  if (!isApplicable) {
    return (
      <Notifications
        description={["Location Layout is not applicable for your site."]}
        variant="warning"
      />
    );
  }

  const getInfoMessage = () => {
    return (
      <>
        <McNotification appearance="info" fit="small">
          <ul>
            <StyledListItem>
              <p>
                You can upload files in the following formats: .jpg, .jpeg,
                .png, .bmp
              </p>
            </StyledListItem>
            <StyledListItem>
              <p>Maximum file size is 10MB.</p>
            </StyledListItem>
            <StyledListItem>
              <p>
                Once all the uploaded files are sanitised, the status turns to
                green.
              </p>
            </StyledListItem>
          </ul>
        </McNotification>
      </>
    );
  };

  return (
    <SiteElementLayout
      heading="Location Layout"
      sectionId={sectionId}
      popoverContent={getInfoMessage()}
    >
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={getUploadedFiles}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
          handleStatusChange();
        }}
      />
      <SiteElementsRenderer mode={mode}>
        <McFileUpload
          key={isFilesUploaded ? 1 : 0}
          fit="small"
          variant="drag-drop"
          accept=".jpg,.jpeg,.png,.bmp"
          multiple
          input={(e: any) => handleInputChange(e)}
        />
        <SaveButtonWrapper>
          <Button
            fit="small"
            label="Save"
            click={() => save()}
            disabled={selectedFiles === undefined}
            multiple
          />
        </SaveButtonWrapper>
      </SiteElementsRenderer>
      <Label> Uploaded Files </Label>
      {locationLayout}
    </SiteElementLayout>
  );
}

export const SaveButtonWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const CardWrapper = styled(Box)`
  padding: 10px 10px 10px 0;
`;

const UploadedFilesWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  overflow-x: auto;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileNameContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
`;

const Label = styled.div`
  padding: "10px 0 10px 0";
`;
