export const Roles = {
  GSCADMIN: "BR_GSCAdmin",
  PROCESSOWNER: "BR_Process_Write",
  SITECOORDINATOR: "BR_SiteDetails_Write",
  SITEOWNER: "BR_SiteDetails_Approve",
  SITEUSER: "BR_SiteUser",
  BRADMIN: "BR_SiteDetails_Validate",
  OFFICEUSER: "BR_OfficeUser",
  SNRADMIN: "BR_SNRAdmin",
};

export const ConcreteRoles = {
  GSCADMIN: "Admin",
  PROCESSOWNER: "Process Owner",
  SITECOORDINATOR: "Site Coordinator",
  SITEOWNER: "Site Owner",
  SITEUSER: "Site User",
  BRADMIN: "BR Manager",
  OFFICEUSER: "Office User",
  SNRADMIN: "S&R Admin",
};