import { v4 as uuidv4 } from 'uuid';
import { getAccessTokenForStrategy } from './auth-utils';
import { accessTokenStrategy } from '../constants/App';

export const doGet: (url: string, tokenStrategy?: string) => Promise<any> = (
  url,
  tokenStrategy = accessTokenStrategy.DEFAULT,
) => {
  const options = {
    method: 'GET',
  };
  return doRequest(url, options, true, true, tokenStrategy);
};

export const doGetWithoutAutheader: (url: string) => Promise<any> = (url) => {
  const options = {
    method: 'GET',
  };
  return doRequest(url, options, false);
};

export const doPostWithoutBody: (url: string) => Promise<any> = (url) => {
  const options = {
    method: 'POST',
  };
  return doRequest(url, options);
};

export const doPost: (url: string, body?: any) => Promise<any> = (url, body) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  };
  return doRequest(url, options);
};

export const doPostWithMultipart: (url: string, body: any) => Promise<any> = (url, body) => {
  const options = {
    method: 'POST',
    body: body,
  };
  return doRequest(url, options, true, false);
};

export const doPutWithoutBody: (url: string, body?: any, tokenStrategy?: string) => Promise<any> = (
  url,
  body,
  tokenStrategy = accessTokenStrategy.DEFAULT,
) => {
  const options = {
    method: 'PUT',
    body: body === '' ? JSON.stringify('') : JSON.stringify(body),
  };
  return doRequest(url, options, true, true, tokenStrategy);
};

export const doPut: (url: string, body?: any, tokenStrategy?: string) => Promise<any> = (
  url,
  body,
  tokenStrategy = accessTokenStrategy.DEFAULT,
) => {
  const options = {
    method: 'PUT',
    body: body ? JSON.stringify(body) : null,
  };
  return doRequest(url, options, true, true, tokenStrategy);
};

export const doDelete: (url: string, body?: any) => Promise<any> = (url, body?) => {
  const options: { method: string; body?: string } = {
    method: 'DELETE',
  };
  if (body) {
    options.body = JSON.stringify(body);
  }
  return doRequest(url, options);
};

const doRequest = async (
  url: string,
  options: any,
  setauthHeader: boolean = true,
  isApplicationJson: boolean = true,
  tokenStrategy: string = accessTokenStrategy.DEFAULT,
) => {
  const requestHeaders = options.headers || new Headers();
  if ((window as any).config?.consumerKey) {
    requestHeaders.append('consumer-key', (window as any).config.consumerKey);
  }

  if (!requestHeaders.has('Content-Type')) {
    if (isApplicationJson) {
      requestHeaders.append('Content-Type', 'application/json');
    }
    requestHeaders.append('cache', 'no-cache');
  }

  if (options.method === 'POST' || options.method === 'PUT') {
    const guid = uuidv4();
    requestHeaders.append('requestid', guid);
  }
  if (setauthHeader) {
    const token = await getAccessTokenForStrategy(tokenStrategy);
    requestHeaders.append('Authorization', `Bearer ${token}`);
  }

  const updatedOptions = { ...options, headers: requestHeaders };
  return new Promise((resolve, reject) => {
    fetch(url, updatedOptions)
      .then((response: Response) => {
        if (!response.ok) {
          response
            .json()
            .then((json) => {
              reject({
                statusText: `${response.status}: ${response.statusText}`,
                message: json.message ? json.message : json.title,
                statusCode: response.status,
                body: json,
              });
            })
            .catch(() => {
              /* If JSON cannot be parsed, just convey the HTTP status code */
              reject(`Request failed with status code ${response.status}`);
            });
        } else if (
          response.headers.has('Content-Type') &&
          (response.headers.get('Content-Type') || '').includes('application/json')
        ) {
          resolve(response.json());
        } else if (
          response.headers.has('Content-Type') &&
          (response.headers.get('Content-Type') || '').includes('text/plain')
        ) {
          resolve(response.text());
        } else if (
          response.headers.has('Content-Type') &&
          ((response.headers.get('Content-Type') || '').includes('image/png') ||
            (response.headers.get('Content-Type') || '').includes('text/plain') ||
            (response.headers.get('Content-Type') || '').includes('application/pdf') ||
            (response.headers.get('Content-Type') || '').includes('image/jpeg') ||
            (response.headers.get('Content-Type') || '').includes('image/jpg') ||
            (response.headers.get('Content-Type') || '').includes('image/gif') ||
            (response.headers.get('Content-Type') || '').includes('image/bmp'))
        ) {
          resolve(response.blob());
        } else {
          resolve(response);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};
