import {
  ItemType,
  TabItemProps,
  Tabs,
} from "@maersk-global/community-react-tabs";
import { useEffect, useState } from "react";
import Box from "../../components/common/box";
import SiteDetails from "./SiteDetails";
import styled from "styled-components";
import {
  TabItemTypes,
  tabItemCodes,
  tabItemTitles,
  tabItems as tabItemsConstant,
} from "../../constants/TabItems";
import useSite from "../../hooks/useSite";
import OperationalParameters from "./OperationalParameters";
import SiteTabsTitle from "../../components/common/siteTabStatusIndicator";
import { getTabsStatus } from "../../services/Site/Review";
import { toast } from "../../components/common/toast";
import LocationLayout from "./LocationLayout";
import { siteComponentKeys } from "../../constants/Site";
import SiteProcessDependencies from "./ProcessDependencies/SiteProcessDependencies";
import SiteProcesses from "./Impact/SiteProcesses";
import AlternativeLocation from "./AlternativeLocation/AlternativeLocations";
import SiteContinuityPlan from "./ContinuityPlan/ContinuityPlan";
import SiteContacts from "./Contacts/SiteContacts";
import SiteCustomers from "./Customers/SiteCustomers";
import { useLocation } from "react-router-dom";

export default function SiteTabs({
  items,
  onItemSaved,
}: Readonly<{
  items: ItemType[];
  onItemSaved: (isAllKeysSubmitted: boolean) => void;
}>) {
  const [tabItems, setTabItems] = useState<ItemType[]>(items);
  const [activeTabCode, setActiveTabCode] = useState<string>(items[0].id);
  const [componentStatus, setComponentStatus] = useState<any>(null);
  const [componentIds, setComponentIds] = useState<any>(null);

  const { siteId, site } = useSite();
  const location = useLocation();

  useEffect(() => {
    if (siteId > 0) {
      getAllTabStatus(false);
    }
  }, [siteId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const someQueryParam = queryParams.get("tab");

    if (someQueryParam) {
      const tabItemIndex = tabItemsConstant[TabItemTypes.SITE].findIndex(
        (item) => item.id === someQueryParam
      );

      if (tabItemIndex > -1) {
        updateTabItems(tabItemIndex);
        setActiveTabCode(someQueryParam);
      }
    } else {
      updateTabItems(0);
    }
  }, [location.search]);

  const updateTabItems = (activeIndex: number) => {
    const updatedTabItems = tabItems.map((item, index) => ({
      ...item,
      active: index === activeIndex,
    }));
    setTabItems(updatedTabItems);
  };

  const getAllTabStatus = (shouldSetTabCode: boolean) => {
    getTabsStatus(site.versionId)
      .then((res) => {
        setComponentStatus(res);
        setComponentIds({
          locationLayout: res[siteComponentKeys.locationLayout].id,
          operationalSchedule: res[siteComponentKeys.operationalSchedule].id,
          seasonalVariation: res[siteComponentKeys.seasonalVariation].id,
          staffingSecurity: res[siteComponentKeys.staffingSecurity].id,
          keyCustomer: res[siteComponentKeys.keyCustomer].id,
          impacts: res[siteComponentKeys.impacts].id,
          dependencies: res[siteComponentKeys.dependencies].id,
          continuityPlan: res[siteComponentKeys.continuityPlan].id,
          alternativeLocation: res[siteComponentKeys.alternativeLocation].id,
          internalContacts: res[siteComponentKeys.internalContacts].id,
          externalContacts: res[siteComponentKeys.externalContacts].id,
          emergencyContacts: res[siteComponentKeys.emergencyContacts].id,
        });
        if (shouldSetTabCode) {
          calculateTabStatus(res);
          setActiveTabCode(items[0].id);
        } else {
          let updatedTabItems = [...tabItems];
          calculateTabStatus(res, updatedTabItems);
        }
      })
      .catch((error: any) => {
        toast("Error", error.message, "error");
      });
  };

  const setActiveTab = (e: TabItemProps) => {
    setTabItems(e.updatedTabData);
    setActiveTabCode(e.selectedItemId);
  };

  const calculateTabStatus = (
    submissionStatuses: {
      [key: string]: {
        status: boolean;
        id: number;
      };
    },
    updatedTabItems?: any
  ) => {
    if (!updatedTabItems) updatedTabItems = tabItemsConstant[TabItemTypes.SITE];

    let titles = [
      {
        id: tabItemCodes.siteDetails,
        title: tabItemTitles.siteDetails,
        isValid: submissionStatuses[siteComponentKeys.locationLayout].status,
      },
      {
        id: tabItemCodes.operationalParameters,
        title: tabItemTitles.operationalParameters,
        isValid:
          submissionStatuses[siteComponentKeys.operationalSchedule].status &&
          submissionStatuses[siteComponentKeys.seasonalVariation].status &&
          submissionStatuses[siteComponentKeys.staffingSecurity].status,
      },
      {
        id: tabItemCodes.keyCustomers,
        title: tabItemTitles.keyCustomers,
        isValid: submissionStatuses[siteComponentKeys.keyCustomer].status,
      },
      {
        id: tabItemCodes.impact,
        title: tabItemTitles.impact,
        isValid: submissionStatuses[siteComponentKeys.impacts].status,
      },
      {
        id: tabItemCodes.dependencies,
        title: tabItemTitles.dependencies,
        isValid: submissionStatuses[siteComponentKeys.dependencies].status,
      },
      {
        id: tabItemCodes.continuityPlan,
        title: tabItemTitles.continuityPlan,
        isValid: submissionStatuses[siteComponentKeys.continuityPlan].status,
      },
      {
        id: tabItemCodes.alternativeLocation,
        title: tabItemTitles.alternativeLocation,
        isValid:
          submissionStatuses[siteComponentKeys.alternativeLocation].status,
      },
      {
        id: tabItemCodes.internalExternalContacts,
        title: tabItemTitles.internalExternalContacts,
        isValid:
          submissionStatuses[siteComponentKeys.internalContacts].status &&
          submissionStatuses[siteComponentKeys.externalContacts].status &&
          submissionStatuses[siteComponentKeys.emergencyContacts].status,
      },
    ];

    let isAllKeysSubmitted =
      submissionStatuses[siteComponentKeys.operationalSchedule].status &&
      submissionStatuses[siteComponentKeys.seasonalVariation].status &&
      submissionStatuses[siteComponentKeys.staffingSecurity].status &&
      submissionStatuses[siteComponentKeys.locationLayout].status &&
      submissionStatuses[siteComponentKeys.impacts].status &&
      submissionStatuses[siteComponentKeys.dependencies].status &&
      submissionStatuses[siteComponentKeys.continuityPlan].status &&
      submissionStatuses[siteComponentKeys.alternativeLocation].status &&
      submissionStatuses[siteComponentKeys.internalContacts].status &&
      submissionStatuses[siteComponentKeys.externalContacts].status &&
      submissionStatuses[siteComponentKeys.emergencyContacts].status &&
      submissionStatuses[siteComponentKeys.keyCustomer].status;

    onItemSaved(isAllKeysSubmitted);

    let newTabItems = updatedTabItems.map((item: any) => {
      let matchingTitle = titles.find((title) => title.id === item.id);
      if (matchingTitle) {
        return {
          ...item,
          title: (
            <SiteTabsTitle
              title={matchingTitle.title}
              isValid={matchingTitle.isValid}
            />
          ),
        };
      }
      return item;
    });

    setTabItems(newTabItems);
  };

  const handleStatusChange = () => {
    getAllTabStatus(false);
  };

  return (
    <Box display="grid" gridTemplateColumns="1fr">
      <Tabs
        items={tabItems}
        onClick={setActiveTab}
        fit="small"
        type="overflow"
        className="site-tabs"
        key={siteId}
      />
      {componentIds != null ? (
        <SiteBCMTabContentWrapper>
          {activeTabCode === tabItemCodes.siteDetails && (
            <>
              <SiteDetails />
              <LocationLayout
                handleStatusChange={handleStatusChange}
                sectionId={componentIds.locationLayout}
              />
            </>
          )}
          {activeTabCode === tabItemCodes.operationalParameters && (
            <OperationalParameters
              handleStatusChange={handleStatusChange}
              operationalScheduleSectionId={componentIds.operationalSchedule}
              seasonalVariationSectionId={componentIds.seasonalVariation}
              staffingSecuritySectionId={componentIds.staffingSecurity}
            />
          )}
          {activeTabCode === tabItemCodes.keyCustomers && (
            <SiteCustomers
              handleStatusChange={handleStatusChange}
              sectionId={componentIds.keyCustomer}
            />
          )}
          {activeTabCode === tabItemCodes.impact && (
            <SiteProcesses
              handleStatusChange={handleStatusChange}
              sectionId={componentIds.impacts}
              componentStatus={componentStatus}
            />
          )}
          {activeTabCode === tabItemCodes.dependencies && (
            <SiteProcessDependencies
              handleStatusChange={handleStatusChange}
              sectionId={componentIds.dependencies}
              componentStatus={componentStatus}
            />
          )}
          {activeTabCode === tabItemCodes.continuityPlan && (
            <SiteContinuityPlan
              handleStatusChange={handleStatusChange}
              sectionId={componentIds.continuityPlan}
              componentStatus={componentStatus}
            />
          )}
          {activeTabCode === tabItemCodes.alternativeLocation && (
            <AlternativeLocation
              handleStatusChange={handleStatusChange}
              sectionId={componentIds.alternativeLocation}
            />
          )}
          {activeTabCode === tabItemCodes.internalExternalContacts && (
            <SiteContacts
              componentStatus={componentStatus}
              handleStatusChange={handleStatusChange}
              internalContactsSectionId={componentIds.internalContacts}
            />
          )}
        </SiteBCMTabContentWrapper>
      ) : (
        <></>
      )}
    </Box>
  );
}

const SiteBCMTabContentWrapper = styled.div`
  padding-top: 10px;
`;
