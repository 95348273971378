import { useContext, useEffect, useReducer, useState } from "react";
import { loadingIndicator } from "../../../components/common/loading";
import Welcome from "../../Welcome";
import AddEditAlternativeLocation from "./modals/LocationModal";
import Notifications from "../../../components/common/notifications";
import Heading from "../../../components/common/heading";
import { Button } from "../../../components/common/button";
import { Box } from "@maersk-global/community-react";
import { FlexGrowBox } from "../../../components/common/common.styles";
import { McButton, McTag } from "@maersk-global/mds-react-wrapper";
import { DeleteIcon } from "../../../components/common/icons";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../../reducers/delete";
import DeleteModal from "../../../components/common/deleteModal";
import { getSingleSiteConfiguration } from "../../../services/SiteConfiguration";
import useSite from "../../../hooks/useSite";
import {
  getAlternativeLocation,
  getAlternativeLocationSites,
} from "../../../services/Site/AlternativeLocation";
import { toastError } from "../../../components/common/toast";
import ConfigurableComponentsRenderer from "../../../components/common/configurableItemsRenderer";
import LayoutOfWarehouse from "./LayoutOfWarehouse";
import { CardKeyValueDisplay } from "../../../components/common/cardKeyValueDisplay";
import { ModeContext } from "../SiteBCM";
import SiteElementsRenderer from "../../../components/common/siteElementsRenderer";
import SiteElementLayout from "../../../components/common/siteElementLayout";
import HorizontalLine from "../../../components/common/horizontalLine";
import { SiteModes } from "../../../constants/SiteBCM";
import { chunkArray } from "../../../utils/utils";
import { AlternativeLocationModel, ConfigurationMapping } from "./modals/LocationModal.types";
import { alternativeLocationKeys } from "./modals/LocationModal.utils";


export default function AlternativeLocation({
  handleStatusChange,
  sectionId,
}: Readonly<{
  handleStatusChange: () => void;
  sectionId: number;
}>) {
  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [isApplicable, setIsApplicable] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [sites, setSites] = useState<any[]>([]);
  const [alternateLocations, setAlternateLocations] = useState<any[]>([]);
  const [alternativeLocations, setAlternativeLocations] = useState<any[]>([]);
  const [configurations, setConfigurations] = useState<
    ConfigurationMapping | undefined
  >(undefined);
  const [editableAlternateLocation, setEditableAlternateLocation] = useState<
    any | null
  >(null);

  const [expanded, setExpanded] = useState<any[]>([]);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );

  useEffect(() => {
    if (siteId > 0) {
      getSingleSiteConfiguration(
        site.id,
        site.versionId,
        "alternative-location"
      )
        .then((response) => {
          if (response.length === 0) {
            setIsApplicable(false);
            setLoading(false);
          }

          let mappedResponse: ConfigurationMapping = response.reduce(
            (acc, item) => {
              return {
                ...acc,
                [item.key]: item.configurationValue,
              };
            },
            {}
          );
          setConfigurations(mappedResponse);
        })
        .catch((error) => {
          toastError(error.message);
        })
        .then(() => {
          if (isApplicable) {
            getAlternativeLocationSites(site.archeTypeId, site.id)
              .then((response) => {
                setSites(response);
              })
              .catch((error) => {
                toastError(error.message);
              })
              .then(() => {
                getAlternativeLocations();
              });
          }
        });
    }
  }, [siteId]);

  const getAlternativeLocations = () => {
    getAlternativeLocation(site.id, site.versionId)
      .then((response) => {
        setAlternateLocations(response);
        if (response.length > 0) {
          let chunkedArray = chunkArray(response, 2);
          setAlternativeLocations(chunkedArray);
        } else {
          setAlternativeLocations([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const onAddNewAlternativeLocationClick = () => {
    setIsModalOpen(true);
  };

  const expandableControl = (id: number) => {
    return (
      <Box display="flex">
        <FlexGrowBox />
        <Box>
          <McButton
            icon="expand"
            appearance="neutral"
            variant="outlined"
            fit="small"
            padding="default"
            click={() => handleAlternateLocationExpansion(id)}
          />
        </Box>
      </Box>
    );
  };

  const handleAlternateLocationExpansion = (id: number) => {
    setExpanded((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const AlternativeLocationCard = ({
    alternativeLocation,
    id,
  }: {
    alternativeLocation: AlternativeLocationModel;
    id: number;
  }) => {
    const siteName: string = alternativeLocation.isExternal ? alternativeLocation.externalSiteName ?? "" : alternativeLocation.siteName ?? "";
    return (
      <>
        <Box display="flex">
          <Box>
            <b>{siteName}</b>
          </Box>
          <FlexGrowBox />
          <SiteElementsRenderer mode={mode}>
            <Box display="flex" style={{ gap: "8px" }}>
              <McButton
                icon="pencil"
                appearance="neutral"
                variant="outlined"
                fit="small"
                click={() => {
                  let editableItem = alternateLocations.find((item) => {
                    return item.id === alternativeLocation.id;
                  });
                  setEditableAlternateLocation(editableItem);
                  setIsModalOpen(true);
                }}
              />
              <McButton
                appearance="neutral"
                variant="outlined"
                fit="small"
                click={() =>
                  onDeleteEntity(
                    dispatchDelete,
                    id,
                    "AlternativeLocation",
                    siteName,
                    "alternativelocation",
                    ""
                  )
                }
              >
                <span slot="icon">
                  <DeleteIcon />
                </span>
              </McButton>
            </Box>
          </SiteElementsRenderer>
        </Box>
        <HorizontalLine />
        <Box style={{ position: "relative" }}>
          {alternativeLocation.isExternal ? 
          <CardKeyValueDisplay value={alternativeLocation?.externalSiteRemarks} />
          : internalLocationCardContent(alternativeLocation, id)}
           <McTag
            appearance="neutral-weak" 
            style={{ position: "absolute", right: "10px", top: "10px" }}
          >
            {alternativeLocation.isExternal ? "External" : "Internal"}
          </McTag>
        </Box>
      </>
    );
  };

  const internalLocationCardContent = (alternativeLocation: AlternativeLocationModel, id: number) => {
    return (
      <>
       <CardKeyValueDisplay value={alternativeLocation.siteOwner} />
        <CardKeyValueDisplay value={alternativeLocation.siteOperator} />
        <CardKeyValueDisplay value={alternativeLocation.address} />
        {!expanded[id] && expandableControl(id)}
        <Box
          style={{
            display: expanded[id] ? "" : "none",
          }}
        >
          <br />
          <b>WAREHOUSE DETAILS</b>

          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.warehouseType}
          >
            <CardKeyValueDisplay
              label="Warehouse Type"
              value={alternativeLocation.wareHouseType}
            />
          </ConfigurableComponentsRenderer>
          <CardKeyValueDisplay label="Layout of Warehouse" value={""} />
          <LayoutOfWarehouse fileDetails={alternativeLocation.fileDetails} />
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.distanceToMajorPort}
          >
            <CardKeyValueDisplay
              label="Distance to Major Port"
              value={`${alternativeLocation.distanceToMajorPort} km`}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.distanceFromCurrentWarehouse}
          >
            <CardKeyValueDisplay
              label="Distance from Current Warehouse"
              value={`${alternativeLocation.distanceFromCurrentWarehouse} km`}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.wmsUsed}
          >
            <CardKeyValueDisplay
              label="WMS Used"
              value={
                alternativeLocation.wmsUsed === null
                  ? "NA"
                  : alternativeLocation.wmsUsed
                  ? "Yes"
                  : "No"
              }
            />
            <CardKeyValueDisplay
              label="WMS Type"
              value={alternativeLocation.wmsType}
            />
          </ConfigurableComponentsRenderer>
          <br />
          <b>OTHER</b>
          <br />
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.businessContinuityTime}
          >
            <CardKeyValueDisplay
              label="Business Continuity Time"
              value={`${alternativeLocation.businessContinuityTime} ${alternativeLocation.businessContinuityTimeUnit}`}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.fteCountForSupport}
          >
            <CardKeyValueDisplay
              label="FTE Count for Support"
              value={alternativeLocation.fteCountForSupport ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.bcpStrategy}
          >
            <CardKeyValueDisplay
              label="BCP Strategy"
              value={alternativeLocation.bcpStartegy}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.shiftDetails ?? ""}
          >
            <CardKeyValueDisplay
              label="Shift Details"
              value={alternativeLocation.shiftDetails ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.itAppsAccessRequirements}
          >
            <CardKeyValueDisplay
              label="IT Apps Access Requirements"
              value={alternativeLocation.itAppsAccessRequirements ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.constraintsImpactingContinuity}
          >
            <CardKeyValueDisplay
              label="Constraints impacting continuity"
              value={
                alternativeLocation.constraintsImpactingContinuity === null
                  ? "NA"
                  : alternativeLocation.constraintsImpactingContinuity
                  ? "Yes"
                  : "No"
              }
            />
            <CardKeyValueDisplay
              label="Constraints impacting continuity remarks"
              value={
                alternativeLocation.constraintsImpactingContinuityRemarks ?? ""
              }
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.activationPreparation}
          >
            <CardKeyValueDisplay
              label="Activation Preparation"
              value={
                alternativeLocation.activationPreparation === null
                  ? "NA"
                  : alternativeLocation.activationPreparation
                  ? "Yes"
                  : "No"
              }
            />
            <CardKeyValueDisplay
              label="Activation Preparation Remarks"
              value={alternativeLocation.activationPreparationRemarks ?? ""}
            />
          </ConfigurableComponentsRenderer>
          <ConfigurableComponentsRenderer
            configurations={configurations}
            configKey={alternativeLocationKeys.otherRemarks}
          >
            <CardKeyValueDisplay
              label="Other Remarks"
              value={alternativeLocation.otherRemarks ?? ""}
            />
          </ConfigurableComponentsRenderer>
          {expanded[id] && expandableControl(id)}
        </Box>
      </>
    );
  };

  const onSave = () => {
    getAlternativeLocations();
    handleStatusChange();
  };

  if (loading) return loadingIndicator;

  if (!isApplicable) {
    return (
      <Notifications
        description={["This section is not applicable for your site."]}
        variant="warning"
      />
    );
  }

  const onModalClose = () => {
    setEditableAlternateLocation(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onSave}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
          handleStatusChange();
        }}
      />
      <AddEditAlternativeLocation
        isOpen={isModalOpen}
        configurations={configurations}
        editableAlternativeLocation={editableAlternateLocation}
        onClose={onModalClose}
        sites={sites}
        onSave={onSave}
      />
      {alternativeLocations.length === 0 ? (
        <Welcome
          heading={"Alternative Location"}
          subHeading={
            "There’s no alternative location for the site. Start adding one."
          }
          buttonLabel={"Add New alternative location"}
          icon="plus"
          onButtonClick={onAddNewAlternativeLocationClick}
          isDisabled={mode === SiteModes.Read}
        />
      ) : (
        <SiteElementLayout heading="Alternative Location" sectionId={sectionId}>
          <SiteElementsRenderer mode={mode}>
            <Heading heading={""} subheading={""}>
              <Button
                click={onAddNewAlternativeLocationClick}
                label="Add New alternative location"
                fit="medium"
              />
            </Heading>
          </SiteElementsRenderer>
          {alternativeLocations.map((chunk: any, chunkIndex: number) => (
            <Box
              key={chunkIndex}
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="10px"
            >
              {chunk.map((alternativeLocation: AlternativeLocationModel) => (
                <Box
                  key={alternativeLocation.id}
                  style={{
                    padding: "10px",
                    border:
                      "1px solid var(--mds_brand_appearance_neutral_weak_border-color)",
                    boxSizing: "border-box",
                    height: expanded[alternativeLocation.id] ? "auto" : "180px",
                    overflow: "auto",
                    margin: "8px",
                  }}
                >
                  <AlternativeLocationCard
                    key={alternativeLocation.id}
                    alternativeLocation={alternativeLocation}
                    id={alternativeLocation.id}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </SiteElementLayout>
      )}
    </>
  );
}
