import { SidebarItemsProps } from '@maersk-global/community-react-sidebar';
import { Roles } from './Roles';

export const navItems: { [role: string]: Array<SidebarItemsProps> } = {
  [Roles.GSCADMIN]: [
    {
      id: 'hierarchy',
      icon: 'warehouse',
      title: 'Hierarchy',
      path: '/hierarchy',
    },
    {
      id: "master-data",
      icon: "file",
      title: "Library",
      path: "/master-data",
    },
    {
      id: 'user-management',
      icon: 'user-plus-circle',
      title: 'User Management',
      path: '/user-management',
    },
    {
      id: 'customers',
      icon: 'user-key',
      title: 'Customers',
      path: '/customers',
    },
  ],
  [Roles.PROCESSOWNER]: [
    {
      id: "master-data",
      icon: "file",
      title: "Library",
      path: "/master-data",
    },
  ],
  [Roles.SITECOORDINATOR]: [
    {
      id: 'siteCode',
      icon: 'warehouse',
      title: 'Site Code',
      path: '/siteCode',
      subMenu: [
        {
          id: 'dashboard',
          icon: 'house',
          title: 'Dashboard',
          path: '/dashboard/:id',
        },
        {
          id: 'business-continuity-management',
          icon: 'file-eye',
          title: 'BCM',
          path: '/business-continuity-management/:id',
        },
      ],
    },
  ],
  [Roles.BRADMIN]: [
    {
      id: 'site-overview',
      icon: 'house',
      title: 'Site Overview',
      path: '/site-overview',
    },
  ],
  [Roles.OFFICEUSER]: [
    {
      id: 'site-overview',
      icon: 'house',
      title: 'Site Overview',
      path: '/site-overview',
    },
  ],
  [Roles.SNRADMIN]: [
    {
      id: 'hierarchy',
      icon: 'warehouse',
      title: 'Hierarchy',
      path: '/hierarchy',
    },
    {
      id: "master-data",
      icon: "file",
      title: "Library",
      path: "/master-data",
    },
    {
      id: 'user-management',
      icon: 'user-plus-circle',
      title: 'User Management',
      path: '/user-management',
    },
    {
      id: 'customers',
      icon: 'user-key',
      title: 'Customers',
      path: '/customers',
    },
    {
      id: 'site-overview',
      icon: 'house',
      title: 'Site Overview',
      path: '/site-overview',
    },
  ],
};
