import { toastError } from "../../components/common/toast";
import { doGet, doPostWithMultipart } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";
import { getFileNameFromPath } from "../../utils/utils";

export const getLocationLayout = (
  siteId: number,
  version: number
): Promise<any> => doGet(`${getApiUrl()}/locationlayout/${siteId}/${version}`);

export const downloadLocationLayoutFile = (
  siteId: number,
  path: string
): Promise<any> =>
  doGet(`${getApiUrl()}/file/download/${siteId}?filepath=${path}`);

export const saveLocationLayout = (request: any): Promise<any> =>
  doPostWithMultipart(`${getApiUrl()}/locationlayout`, request);

export const downloadFile = (siteId: number, filepath: string) => {
  downloadLocationLayoutFile(siteId, filepath)
    .then((response: any) => {
      return response;
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob); // create a URL representing the Blob
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", getFileNameFromPath(filepath));
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) link.parentNode.removeChild(link);
    })
    .catch((error) => {
      toastError(error.message);
    });
};