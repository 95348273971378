import { configuration } from "../../../../constants/Site";
import {
    timeIntervalOption
  } from "../../../../constants/SelectItems";
import { AlternativeLocationModel, ConfigurationMapping, AlternativeLocationKeys } from "./LocationModal.types";

export const expandableSections = [
    {
      label: "Site Details",
      isExpanded: false,
    },
    {
      label: "Warehouse Details",
      isExpanded: false,
    },
    {
      label: "Others",
      isExpanded: false,
    },
  ];

  export const alternativeLocationKeys: AlternativeLocationKeys = {
    siteId: "site-id",
    siteOperator: "site-operator",
    warehouseType: "warehouse-type",
    distanceToMajorPort: "distance-to-major-port",
    distanceFromCurrentWarehouse: "distance-from-current-warehouse",
    wmsUsed: "wms-used",
    businessContinuityTime: "business-continuity-time",
    fteCountForSupport: "fte-count-for-support",
    bcpStrategy: "bcp-strategy",
    shiftDetails: "shift-details",
    itAppsAccessRequirements: "it-apps-access-requirements",
    constraintsImpactingContinuity: "constraints-impacting-continuity",
    activationPreparation: "activation-preparation",
    otherRemarks: "remarks",
  };
  
  export const warehouseTypeOptions = [
    { label: "Air", value: "1" },
    { label: "Cold Storage", value: "2" },
    { label: "Consolidation", value: "3" },
    { label: "Deconsolidation", value: "4" },
    { label: "Depot", value: "5" },
    { label: "Fulfilment", value: "6" },
    { label: "Maersk e-delivery", value: "7" },
    { label: "Maersk e-fulfilment", value: "8" },
    { label: "SCM Consolidation", value: "9" },
    { label: "Transportation", value: "10" },
    { label: "Transportation - delivery stations", value: "11" },
    { label: "Transportation - sortation hubs", value: "12" },
  ];
  
  export const bcpStrategyOptions = [
    { label: "WLD", value: "1" },
    { label: "CT", value: "2" },
    { label: "Split operations", value: "3" },
  ];
  
  export const defaultAlternativeLocation: AlternativeLocationModel = {
    id: 0,
    siteId: 0,
    versionId: 0,
    alternativeSiteId: 0,
    siteOperator: null,
    wareHouseTypeId: null,
    fileDetails: [],
    distanceToMajorPort: null,
    distanceFromCurrentWarehouse: null,
    wmsUsed: null,
    wmsType: null,
    businessContinuityTime: null,
    fteCountForSupport: null,
    bcpStartegyId: null,
    shiftDetails: null,
    itAppsAccessRequirements: null,
    constraintsImpactingContinuity: null,
    constraintsImpactingContinuityRemarks: null,
    activationPreparation: null,
    activationPreparationRemarks: "",
    otherRemarks: "",
    businessContinuityTimeUnitId: null,
    siteName: "",
    siteOwner: "",
    address: "",
    siteCode: "",
    isExternal: false,
    externalSiteName: "",
    externalSiteRemarks: "",
  };

  export const validateSiteDetails = (editableAlternateLocation: AlternativeLocationModel) => {
    const errors: string[] = [];

    let { siteOperator } = editableAlternateLocation;

    if (!siteOperator) {
      errors.push("Site Operator is required");
    }

    return errors;
  };

  export const isMandatory = (key: string, configurations: ConfigurationMapping | undefined): boolean => {
    if (!configurations) return false;
    return configurations[key] === configuration.Mandatory;
  };

  export const validateWarehouseDetails = (editableAlternateLocation: AlternativeLocationModel, configurations: ConfigurationMapping | undefined) => {
    const errors: string[] = [];
    let {
      wareHouseTypeId,
      distanceToMajorPort,
      distanceFromCurrentWarehouse,
      wmsUsed,
      wmsType,
    } = editableAlternateLocation;

    if (
      isMandatory(alternativeLocationKeys.warehouseType, configurations) &&
      !wareHouseTypeId
    ) {
      errors.push("Warehouse Type is required");
    }
    if (
      isMandatory(alternativeLocationKeys.distanceToMajorPort, configurations) &&
      !distanceToMajorPort
    ) {
      errors.push("Distance to major port is required");
    }

    if (
      distanceToMajorPort &&
      (distanceToMajorPort < 0 || distanceToMajorPort > 10000)
    ) {
      errors.push("Distance to major port should be between 0 and 10000");
    }

    if (
      isMandatory(alternativeLocationKeys.distanceFromCurrentWarehouse, configurations) &&
      !distanceFromCurrentWarehouse
    ) {
      errors.push("Distance from current warehouse is required");
    }

    if (
      distanceFromCurrentWarehouse &&
      (distanceFromCurrentWarehouse < 0 || distanceFromCurrentWarehouse > 10000)
    ) {
      errors.push(
        "Distance from current warehouse should be between 0 and 10000"
      );
    }

    if (isMandatory(alternativeLocationKeys.wmsUsed, configurations) && wmsUsed === null) {
      errors.push("WMS used is required");
    }
    if (wmsUsed !== null && wmsUsed && !wmsType)
      errors.push("WMS type is required");

    return errors;
  };

  export const validateOthers = (editableAlternateLocation: AlternativeLocationModel, configurations: ConfigurationMapping | undefined) => {
    let {
      businessContinuityTime,
      businessContinuityTimeUnitId,
      fteCountForSupport,
      bcpStartegyId,
      shiftDetails,
      itAppsAccessRequirements,
      constraintsImpactingContinuity,
      constraintsImpactingContinuityRemarks,
      activationPreparation,
      activationPreparationRemarks,
      otherRemarks,
    } = editableAlternateLocation;

    const errors: string[] = [];

    if (
      isMandatory(alternativeLocationKeys.businessContinuityTime, configurations) &&
      !businessContinuityTime
    ) {
      errors.push("Business Continuity time is required");
    }

    if (businessContinuityTime && !businessContinuityTimeUnitId) {
      errors.push("Time interval unit is required.");
    }

    if (
      businessContinuityTime &&
      (businessContinuityTime < 0 || businessContinuityTime > 1500) &&
      businessContinuityTimeUnitId === timeIntervalOption.Hours
    ) {
      errors.push(
        "Business Continuity time should be between 0 and 1500 when time unit is hours."
      );
    }

    if (
      businessContinuityTime &&
      (businessContinuityTime < 0 || businessContinuityTime > 60) &&
      businessContinuityTimeUnitId === timeIntervalOption.Days
    ) {
      errors.push(
        "Business Continuity time should be between 0 and 60 when time unit is days."
      );
    }

    if (
      businessContinuityTime &&
      (businessContinuityTime < 0 || businessContinuityTime > 10) &&
      businessContinuityTimeUnitId === timeIntervalOption.Weeks
    ) {
      errors.push(
        "Business Continuity time should be between 0 and 10 when time unit is weeks."
      );
    }

    if (
      isMandatory(alternativeLocationKeys.fteCountForSupport, configurations) &&
      !fteCountForSupport
    ) {
      errors.push("FTE Count for support is required");
    }

    if (
      fteCountForSupport &&
      (fteCountForSupport < 0 || fteCountForSupport > 1000)
    ) {
      errors.push("FTE Count for support should be between 0 and 1000");
    }

    if (isMandatory(alternativeLocationKeys.bcpStrategy, configurations) && !bcpStartegyId) {
      errors.push("BCP Strategy is required");
    }

    if (isMandatory(alternativeLocationKeys.shiftDetails, configurations) && !shiftDetails) {
      errors.push("Shift details is required");
    }

    if (
      isMandatory(alternativeLocationKeys.itAppsAccessRequirements, configurations) &&
      !itAppsAccessRequirements
    ) {
      errors.push("IT Apps/Access requirements is required");
    }

    if (
      isMandatory(alternativeLocationKeys.constraintsImpactingContinuity, configurations) &&
      constraintsImpactingContinuity === null
    ) {
      errors.push("Constraints impacting continuity is required");
    }

    if (
      constraintsImpactingContinuity &&
      !constraintsImpactingContinuityRemarks
    )
      errors.push("Constraints Impacting Continuity Remarks is required");

    if (
      isMandatory(alternativeLocationKeys.activationPreparation, configurations) &&
      activationPreparation === null
    ) {
      errors.push("Activation Preparation is required");
    }

    if (activationPreparation && !activationPreparationRemarks) {
      errors.push("Activation Preparation Remarks is required");
    }

    if (isMandatory(alternativeLocationKeys.otherRemarks, configurations) && !otherRemarks) {
      errors.push("Other Remarks is required");
    }

    return errors;
  };

  export const validateExternalLocation = (externalLocation: AlternativeLocationModel): string[] => {
    const errors: string[] = [];
    const { externalSiteName, externalSiteRemarks } = externalLocation;

    if (!externalSiteName?.trim()) {
      errors.push("Site Name is required");
    }

    if (!externalSiteRemarks?.trim()) {
      errors.push("Remark is required");
    }

    return errors;
  };