import { useEffect, useMemo } from 'react';
import './App.css';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import Layout from './components/layout/layout';
import { getUserInfo } from './utils';
import { buildConcreteRolesFromTokenRoles } from './utils/roles.utils';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance, inProgress } = useMsal();

  let setUserEvent: Promise<any> | undefined;

  const loadSetUserEventModule = () => {
    if (!setUserEvent) {
      setUserEvent = import('./utils/rum').then((module) => module.setUserEvent);
    }
    return setUserEvent;
  };

  if (isAuthenticated) {
    const userDetails = getUserInfo();

    if (userDetails && userDetails.roles) {
      let roles = buildConcreteRolesFromTokenRoles(userDetails.roles);
      loadSetUserEventModule().then((setUserEvent) => {
        if (setUserEvent) {
          setUserEvent({
            email: userDetails.userName,
            role: roles,
          });
        }
      });
    }
  }

  const loginRequest = useMemo(
    () => ({
      account: accounts[0],
      scopes: [(window as any).config.azureAd.scope],
      extraQueryParameters: {
        domain_hint: (window as any).config.azureAd.domain,
      },
    }),
    [accounts],
  );

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest);
    }
  }, [inProgress, instance, isAuthenticated, loginRequest]);

  if (isAuthenticated) {
    return <Layout />;
  }

  return (
    <div>
      {' '}
      {inProgress === InteractionStatus.Logout
        ? 'Please wait until we sign you out'
        : inProgress === InteractionStatus.Login
        ? 'Please wait until we sign you in'
        : ''}
    </div>
  );
};

export default App;
