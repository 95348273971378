import {
  McInput,
  McOption,
  McSelect,
  McTextAndIcon,
  McTextarea,
} from "@maersk-global/mds-react-wrapper";
import { TextAndIconBody } from "../../../Customers/modals/addEditCustomer";
import ConfigurableComponentsRenderer from "../../../../components/common/configurableItemsRenderer";
import { SelectBoolean } from "../../../../components/common/selectBoolean";
import {
  timeIntervalOptions,
} from "../../../../constants/SelectItems";
import Box from "../../../../components/common/box";
import SiteMandatoryLabel from "../../../../components/common/siteMandatoryLabel";
import { warehouseTypeOptions, bcpStrategyOptions, isMandatory, alternativeLocationKeys } from "./LocationModal.utils";
import { ConfigurationMapping, ExpandableSections, AlternativeLocationModel, Sites } from "./LocationModal.types";
import { FC } from "react";

type Props = {
    configurations: ConfigurationMapping | undefined;
    editableAlternateLocation: AlternativeLocationModel;
    expandableSection: ExpandableSections;
    handleSectionExpansion: (index: number) => void;
    handleInputChange: (name: string, value: any) => void;
    handleClearButtonClick: (name: string) => void;
    handleSiteChange: (value: string) => void;
    sites: Sites;
}

const InternalLocationContent : FC<Props> = ({ configurations, editableAlternateLocation, expandableSection, handleSectionExpansion, handleInputChange, handleClearButtonClick, handleSiteChange, sites }) => {
    //extracted all content from LocationModal.tsx without refactoring    
  return (
    <>
    <McTextAndIcon
    label={expandableSection[0].label}
    fit="large"
    icon={expandableSection[0].isExpanded ? "chevron-up" : "chevron-down"}
    onClick={() => {
      handleSectionExpansion(0);
    }}
  />
  <TextAndIconBody isExpanded={expandableSection[0].isExpanded}>
    <McSelect
      label=""
      fit="small"
      value={editableAlternateLocation.alternativeSiteId.toString()}
      optionselected={(e: CustomEvent) => handleSiteChange(e.detail.value)}
      name="siteName"
    >
      {sites.map((item: any) => {
        return (
          <McOption key={item.siteId} value={item.siteId.toString()}>
            {item.siteName} ({item.siteCode})
          </McOption>
        );
      })}
      <SiteMandatoryLabel
        slot="label"
        label="Site Name"
        isMandatory={true}
      />
    </McSelect>
    <McInput
      label="Site Code"
      fit="small"
      value={editableAlternateLocation.siteCode.toString()}
      name="alternativeSiteId"
      input={(e: any) => handleInputChange(e.target.name, e.target.value)}
      disabled
    />
    <McInput
      label="Site Owner"
      fit="small"
      value={editableAlternateLocation.siteOwner}
      input={(e: any) => handleInputChange(e.target.name, e.target.value)}
      name="siteOwner"
      disabled
    />
    <McTextarea
      label="Address"
      fit="small"
      value={editableAlternateLocation.address}
      input={(e: any) => handleInputChange(e.target.name, e.target.value)}
      name="address"
      disabled
    />
    <McInput
      label=""
      fit="small"
      value={editableAlternateLocation.siteOperator}
      input={(e: any) => handleInputChange(e.target.name, e.target.value)}
      name="siteOperator"
    >
      <SiteMandatoryLabel
        slot="label"
        label="Site Operator"
        isMandatory={true}
      />
    </McInput>
  </TextAndIconBody>
  <McTextAndIcon
    label={expandableSection[1].label}
    fit="large"
    icon={expandableSection[1].isExpanded ? "chevron-up" : "chevron-down"}
    onClick={() => {
      handleSectionExpansion(1);
    }}
  />
  <TextAndIconBody isExpanded={expandableSection[1].isExpanded}>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.warehouseType}
    >
      <McSelect
        label=""
        fit="small"
        value={editableAlternateLocation.wareHouseTypeId?.toString()}
        optionselected={(e: CustomEvent) =>
          handleInputChange("wareHouseTypeId", e.detail.value)
        }
        name="wareHouseTypeId"
        clearbutton
        clearbuttonclick={() => handleClearButtonClick("wareHouseTypeId")}
      >
        {warehouseTypeOptions.map((item: any) => {
          return (
            <McOption key={item.value} value={item.value}>
              {item.label}
            </McOption>
          );
        })}
        <SiteMandatoryLabel
          slot="label"
          label="Warehouse Type"
          isMandatory={isMandatory(alternativeLocationKeys.warehouseType, configurations)}
        />
      </McSelect>
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.distanceToMajorPort}
    >
      <McInput
        label=""
        fit="small"
        type="number"
        suffix={"km"}
        value={editableAlternateLocation.distanceToMajorPort?.toString()}
        name="distanceToMajorPort"
        input={(e: any) => {
          handleInputChange(e.target.name, e.target.value);
        }}
      >
        <SiteMandatoryLabel
          slot="label"
          label="Distance to major Port"
          isMandatory={isMandatory(
            alternativeLocationKeys.distanceToMajorPort,
            configurations
          )}
        />
      </McInput>
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.distanceFromCurrentWarehouse}
    >
      <McInput
        label=""
        fit="small"
        type="number"
        suffix={"km"}
        value={editableAlternateLocation.distanceFromCurrentWarehouse?.toString()}
        name="distanceFromCurrentWarehouse"
        input={(e: any) => handleInputChange(e.target.name, e.target.value)}
      >
        <SiteMandatoryLabel
          slot="label"
          label="Distance from current warehouse"
          isMandatory={isMandatory(
            alternativeLocationKeys.distanceFromCurrentWarehouse,
            configurations
          )}
        />
      </McInput>
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.wmsUsed}
    >
      <SelectBoolean
        label="WMS used"
        fit="small"
        value={editableAlternateLocation.wmsUsed}
        onChange={handleInputChange}
        name="wmsUsed"
        clearbutton
        onClearButtonClick={handleClearButtonClick}
      >
        <SiteMandatoryLabel
          slot="label"
          label="WMS used"
          isMandatory={isMandatory(alternativeLocationKeys.wmsUsed, configurations)}
        />
      </SelectBoolean>
      <McInput
        label=""
        fit="small"
        value={editableAlternateLocation.wmsType}
        input={(e: any) => handleInputChange(e.target.name, e.target.value)}
        name="wmsType"
        maxlength={50}
      >
        <SiteMandatoryLabel
          slot="label"
          label="WMS type"
          isMandatory={isMandatory(alternativeLocationKeys.wmsUsed, configurations)}
        />
      </McInput>
    </ConfigurableComponentsRenderer>
  </TextAndIconBody>
  <McTextAndIcon
    label={expandableSection[2].label}
    fit="large"
    icon={expandableSection[2].isExpanded ? "chevron-up" : "chevron-down"}
    onClick={() => {
      handleSectionExpansion(2);
    }}
  />
  <TextAndIconBody isExpanded={expandableSection[2].isExpanded}>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.businessContinuityTime}
    >
      <Box display="grid" gridTemplateColumns="1.5fr 0.5fr" gridGap="1rem">
        <Box>
          <McInput
            label=""
            fit="small"
            value={editableAlternateLocation.businessContinuityTime?.toString()}
            input={(e: any) =>
              handleInputChange(e.target.name, e.target.value)
            }
            type="number"
            name="businessContinuityTime"
          >
            <SiteMandatoryLabel
              slot="label"
              label="Business Continuity time"
              isMandatory={isMandatory(
                alternativeLocationKeys.businessContinuityTime,
                configurations
              )}
            />
          </McInput>
        </Box>
        <Box>
          <McSelect
            label=""
            fit="small"
            value={editableAlternateLocation.businessContinuityTimeUnitId?.toString()}
            input={(e: any) =>
              handleInputChange(e.target.name, e.target.value)
            }
            type="number"
            name="businessContinuityTimeUnitId"
          >
            {timeIntervalOptions.map((item: any) => {
              return (
                <McOption key={item.value} value={item.value.toString()}>
                  {item.label}
                </McOption>
              );
            })}
            <SiteMandatoryLabel
              slot="label"
              label="Time interval unit"
              isMandatory={isMandatory(
                alternativeLocationKeys.businessContinuityTime,
                configurations
              )}
            />
          </McSelect>
        </Box>
      </Box>
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.fteCountForSupport}
    >
      <McInput
        label="FTE Count for support"
        fit="small"
        type="number"
        value={editableAlternateLocation.fteCountForSupport?.toString()}
        input={(e: any) => handleInputChange(e.target.name, e.target.value)}
        name="fteCountForSupport"
      >
        <SiteMandatoryLabel
          slot="label"
          label="FTE Count for support"
          isMandatory={isMandatory(
            alternativeLocationKeys.fteCountForSupport,
            configurations
          )}
        />
      </McInput>
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.bcpStrategy}
    >
      <McSelect
        label=""
        fit="small"
        value={editableAlternateLocation.bcpStartegyId?.toString()}
        optionselected={(e: any) =>
          handleInputChange(e.target.name, e.detail.value)
        }
        name="bcpStartegyId"
        clearbutton
        clearbuttonclick={() => handleClearButtonClick("bcpStartegy")}
      >
        {bcpStrategyOptions.map((item: any) => {
          return (
            <McOption key={item.value} value={item.value}>
              {item.label}
            </McOption>
          );
        })}
        <SiteMandatoryLabel
          slot="label"
          label="BCP Strategy"
          isMandatory={isMandatory(alternativeLocationKeys.bcpStrategy, configurations)}
        />
      </McSelect>
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.shiftDetails}
    >
      <McInput
        label=""
        fit="small"
        value={editableAlternateLocation.shiftDetails}
        input={(e: any) => handleInputChange(e.target.name, e.target.value)}
        name="shiftDetails"
        maxlength={50}
      >
        <SiteMandatoryLabel
          slot="label"
          label="Shift details"
          isMandatory={isMandatory(alternativeLocationKeys.shiftDetails, configurations)}
        />
      </McInput>
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.itAppsAccessRequirements}
    >
      <McInput
        label=""
        fit="small"
        value={editableAlternateLocation.itAppsAccessRequirements}
        input={(e: any) => handleInputChange(e.target.name, e.target.value)}
        name="itAppsAccessRequirements"
        maxlength={500}
      >
        <SiteMandatoryLabel
          slot="label"
          label="IT Apps/Access requirements"
          isMandatory={isMandatory(
            alternativeLocationKeys.itAppsAccessRequirements,
            configurations
          )}
        />
      </McInput>
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.constraintsImpactingContinuity}
    >
      <SelectBoolean
        label=""
        fit="small"
        value={editableAlternateLocation.constraintsImpactingContinuity}
        name="constraintsImpactingContinuity"
        onChange={handleInputChange}
        clearbutton
        onClearButtonClick={handleClearButtonClick}
      >
        <SiteMandatoryLabel
          slot="label"
          label="Constraints impacting continuity"
          isMandatory={isMandatory(
            alternativeLocationKeys.constraintsImpactingContinuity,
            configurations
          )}
        />
      </SelectBoolean>
      <McTextarea
        label="Constraints impacting continuity Remarks"
        fit="small"
        value={
          editableAlternateLocation.constraintsImpactingContinuityRemarks
        }
        input={(e: any) => handleInputChange(e.target.name, e.target.value)}
        name="constraintsImpactingContinuityRemarks"
        maxlength={500}
      />
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.activationPreparation}
    >
      <SelectBoolean
        label="Activation Preparation"
        fit="small"
        value={editableAlternateLocation.activationPreparation}
        name="activationPreparation"
        onChange={handleInputChange}
        clearbutton
        onClearButtonClick={handleClearButtonClick}
      >
        <SiteMandatoryLabel
          slot="label"
          label="Activation Preparation"
          isMandatory={isMandatory(
            alternativeLocationKeys.activationPreparation,
            configurations
          )}
        />
      </SelectBoolean>
      <McTextarea
        label="Activation Preparation Remarks"
        fit="small"
        value={editableAlternateLocation.activationPreparationRemarks}
        input={(e: any) => handleInputChange(e.target.name, e.target.value)}
        name="activationPreparationRemarks"
        maxlength={500}
      />
    </ConfigurableComponentsRenderer>
    <ConfigurableComponentsRenderer
      configurations={configurations}
      configKey={alternativeLocationKeys.otherRemarks}
    >
      <McTextarea
        label="Other Remarks"
        fit="small"
        value={editableAlternateLocation.otherRemarks}
        input={(e: any) => handleInputChange(e.target.name, e.target.value)}
        name="otherRemarks"
        maxlength={500}
      >
        <SiteMandatoryLabel
          slot="label"
          label="Other Remarks"
          isMandatory={isMandatory(alternativeLocationKeys.otherRemarks, configurations)}
        />
      </McTextarea>
    </ConfigurableComponentsRenderer>
  </TextAndIconBody>
  </>
  )
}
export default InternalLocationContent