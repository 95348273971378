import { format } from 'date-fns';
import { mentionPattern } from '../components/comments/constants/patterns';

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return date.toLocaleString('en-US', options);
};

export const chunkArray = (array: any[], size: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const trimStringsBeforeSave = (obj: any) => {
  const trimmedObj: any = {};
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObj[key] = obj[key].trim();
    } else {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj;
};

export const extractMentions = (text: string): string[] => {
  const matches = [];
  let match;
  while ((match = mentionPattern.exec(text)) !== null) {
    matches.push(match[1]); // Extract the email part
  }
  return matches;
};

export const replacePlaceholder = (text: string) => {
  return text.replace(mentionPattern, (match, p1, p2) => `<div class="tagged-persona">${p2}</div>`);
};

export const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .toUpperCase();
};

export const formatDateTime = (dateTime: Date) => {
  return format(new Date(dateTime), 'MMMM dd, yyyy, HH:mm');
};

export const getFileNameFromPath = (filePath: string): string => {
  return filePath.split('/').pop() ?? '';
};
