export const configuration = {
  Mandatory: 1,
  Optional: 2,
  NotApplicable: 3,
};

export const siteBCPStatus = {
  NotStarted: "notstarted",
  InProgress: "inprogress",
  PendingApproval: "pendingapproval",
  Approved: "approved",
  Validated: "validated",
  ReworkInProgress: "reworkinprogress",
  Rejected: "rejected",
};

export const siteBcpStatusSelectList = [
  { label: "Not Started", value: 1 },
  { label: "In Progress", value: 2 },
  { label: "Pending Approval", value: 3 },
  { label: "Approved", value: 4 },
  { label: "Validated", value: 5 },
  { label: "Rework In Progress", value: 6 },
  { label: "Rejected", value: 7 },
];

export const siteBCPReportGenerationStatus = {
  Initiated: 1,
  Completed: 3,
  Error: 4,
  Cancelled: 5,
};

export const siteBCPReportGenerationType = {
  SiteApproved: 1,
  KeyCustomer: 2,
  InternalContact: 3,
};

export const siteComponentKeys = {
  locationLayout: "location-layout",
  operationalSchedule: "operation-schedule",
  staffingSecurity: "staffing-security",
  seasonalVariation: "seasonal-variations",
  keyCustomer: "key-customer",
  impacts: "impact-mappings",
  dependencies: "dependency",
  continuityPlan: "continuity-plan",
  alternativeLocation: "alternative-location",
  emergencyContacts: "emergency-contacts",
  externalContacts: "external-contacts",
  internalContacts: "internal-contacts",
};
