export const formatTimeInterval = (value: string, unit: string) => {
    // Handle decimal values for hours and minutes
    if (value.includes('.') && unit === 'h') {
      const hours = Math.floor(Number(value));
      const minutes = Math.round((Number(value) - hours) * 60);
      const hoursText = hours > 0 ? `${hours}h ` : '';
      const minutesText = minutes > 0 ? `${minutes}m` : '';
      
      return `${hoursText}${minutesText}`.trim();
    }

    // Handle whole hours
    return `${value}${unit}`;
};
  