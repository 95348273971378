import { useEffect, useReducer, useState } from "react";
import Welcome from "../Welcome";
import AddEditProcessModal from "./modals/addEditProcessModal";
import {
  defaultProcesses,
  processesActions,
  processImpactsReducer,
} from "./reducers/processes";
import { toast } from "../../components/common/toast";
import Notifications from "../../components/common/notifications";
import { FlexGrowBox } from "../../components/common/common.styles";
import Accordian from "../../components/common/accordian";
import EditableProcessTable from "./editableProcessTable";
import styled from "styled-components";
import DeleteModal from "../../components/common/deleteModal";
import { loadingIndicator } from "../../components/common/loading";
import { Button } from "../../components/common/button";
import Box from "../../components/common/box";
import { configureProcesssImpact } from "../../services/Processes";
import {
  configureProcessSelection,
  configureSiteProcessImpact,
} from "../../services/Site/Impacts";
import {
  recommendationsActions,
  recommendationsInitialState,
  recommendationsReducer,
} from "../Site/Impact/reducers/recommendations";
import AdditionalLinkText from "../../components/common/additionalLinkText";
import ProcessRecommendationsModal from "../Site/Impact/modals/ProcessRecommendationsModal";
import NotificationWithIcons from "../../components/common/notificationsWithIcon";
import { Roles } from "../../constants/Roles";

export default function Processes({
  processMappings,
  role,
  archeTypeId,
  onSave,
  siteId = 0,
  shouldShowActionButtons = true,
}: any) {
  const [state, dispatch] = useReducer(processImpactsReducer, defaultProcesses);
  const [recommendations, dispatchRecommendations] = useReducer(
    recommendationsReducer,
    recommendationsInitialState
  );

  const [isProcessEditEnabled, setIsProcessEditEnabled] =
    useState<boolean>(false);
  const [isSubProcessEditable, setIsSubProcessEditable] = useState<any>({});

  useEffect(() => {
    if (processMappings) {
      dispatch({
        type: processesActions.SET_PROCESSES,
        value: processMappings,
      });
      dispatch({ type: processesActions.SET_LOADING, loading: false });
    }
  }, [processMappings]);

  const addNewProcessImpactClick = () => {
    dispatch({ type: processesActions.SET_MODAL_OPEN });
  };

  const addEditProcessImpactClick = () => {
    dispatch({
      type: processesActions.SET_EDITABLE_PROCESS,
      value: state.processes,
    });
    dispatch({ type: processesActions.SET_MODAL_OPEN });
  };

  const addNewSubProcessImpactClick = () => {
    dispatch({ type: processesActions.SET_MODAL_OPEN });
  };

  const handleChangeImpactConfigurationClick = () => {
    setIsProcessEditEnabled(true);
  };

  const handleChangeImpactConfigurationSubprocessClick = (processId: any) => {
    setIsSubProcessEditable((prevState: any) => ({
      ...prevState,
      [processId]: true,
    }));
  };

  const handleChangeImpactConfigurationSubprocessCancel = (processId: any) => {
    setIsSubProcessEditable((prevState: any) => ({
      ...prevState,
      [processId]: false,
    }));
  };

  const addEditSubProcessImpactClick = (processId: any) => {
    let process = state.processes.find((item: any) => item.id === processId);
    let mappedSubProcesses = {
      ...process,
      subProcesses: process.subProcessImpactMappings,
    };
    dispatch({
      type: processesActions.SET_EDITABLE_PROCESS,
      value: [mappedSubProcesses],
    });
    dispatch({ type: processesActions.SET_MODAL_OPEN });
  };

  const configureProcessImpact = (
    processImpactMappings: any,
    isProcess: boolean,
    processId: number
  ) => {
    if (role === Roles.GSCADMIN) {
      configureProcesssImpactForAdmin(
        processImpactMappings,
        isProcess,
        processId
      );
    } else {
      configureProcesssImpactForSite(
        processImpactMappings,
        isProcess,
        processId
      );
    }
  };

  const configureProcesssImpactForAdmin = (
    processImpactMappings: any,
    isProcess: boolean,
    processId: number
  ) => {
    configureProcesssImpact(archeTypeId.toString(), processImpactMappings)
      .then((res) => {
        if (res) {
          onSave();
          toast(
            "Success",
            "Process Impact configuration saved successfully",
            "success"
          );
          if (isProcess) {
            setIsProcessEditEnabled(false);
          } else {
            setIsSubProcessEditable((prevState: any) => ({
              ...prevState,
              [processId]: false,
            }));
          }
        }
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  };

  const configureProcesssImpactForSite = (
    processImpactMappings: any,
    isProcess: boolean,
    processId: number
  ) => {
    let updatedProcessImpactMappings = {
      ...processImpactMappings,
      siteId: siteId,
    };

    configureSiteProcessImpact(updatedProcessImpactMappings)
      .then((res) => {
        if (res) {
          onSave();
          toast(
            "Success",
            "Process Impact configuration saved successfully",
            "success"
          );
          if (isProcess) {
            setIsProcessEditEnabled(false);
          } else {
            setIsSubProcessEditable((prevState: any) => ({
              ...prevState,
              [processId]: false,
            }));
          }
        }
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  };

  const onEditCancelClick = () => {
    setIsProcessEditEnabled(false);
  };

  const onDeleteProcess = (
    processId: any,
    isProcess: boolean,
    processName: string,
    info: string
  ) => {
    const entityType = isProcess ? "Process" : "SubProcess";
    let entityPath = "";

    if (isProcess) {
      entityPath = role === Roles.GSCADMIN ? "Process" : "siteprocess";
    } else {
      entityPath =
        role === Roles.GSCADMIN
          ? "Process/subprocess"
          : "siteprocess/subprocess";
    }

    onDeleteEntity(processId, entityType, processName, entityPath, info);
  };

  const onDeleteEntity = (
    id: number,
    key: string,
    keyName: string,
    path: string,
    info: string
  ) => {
    dispatch({
      type: processesActions.SET_DELETE_MODAL_OPEN,
      isDeleteModalOpen: true,
    });
    dispatch({
      type: processesActions.SET_DELETE_ENTITY,
      deleteEntity: { key, id, keyName, path, info },
    });
  };

  const notificationsInfo = () => {
    const hasCriticalityRTO = state.processes.some(
      (process: any) =>
        process.criticalityRtoId !== state.impacts.criticalityRTO &&
        process.criticalityRtoId > 0
    );
    if (hasCriticalityRTO) {
      const impactcriticalityRTO = state.impacts.criticalityMasterValues.find(
        (item: any) => item.id === state.impacts.criticalityRTO
      );
      let processCriticalityRtoId = state.processes.find(
        (process: any) =>
          process.criticalityRtoId !== state.impacts.criticalityRTO &&
          process.criticalityRtoId > 0
      ).criticalityRtoId;
      let processCriticalityRto = state.impacts.criticalityMasterValues.find(
        (item: any) => item.id === processCriticalityRtoId
      );
      let desc = `Current processes is mapped with ${processCriticalityRto.name} criticality whereas on admin its set to ${impactcriticalityRTO.name}. Please map the process to impacts again`;
      return <Notifications description={[desc]} variant={"error"} />;
    }
    return null;
  };

  const onShowLastRecomendationsClick = (
    name: string,
    isProcess: boolean,
    isConfiguredArchetypeLevel: boolean
  ) => {
    dispatchRecommendations({
      type: recommendationsActions.SET_SELECTED_PROCESS,
      selectedProcessName: name,
      isProcess: isProcess,
      isConfiguredArchetypeLevel: isConfiguredArchetypeLevel,
    });
    dispatchRecommendations({ type: recommendationsActions.SET_MODAL_OPEN });
  };

  const showLastRecommendationsModalClose = () => {
    dispatchRecommendations({
      type: recommendationsActions.REMOVE_SELECTED_PROCESS,
    });
    dispatchRecommendations({ type: recommendationsActions.SET_MODAL_CLOSE });
  };

  const mapProcesses = () => {
    return state.isProcess ? (
      <>
        <ActionButtonsWrapper>
          <Box display="flex" p={2}>
            <FlexGrowBox />
            <Box display="flex" style={{ gap: "8px", alignItems: "center" }}>
              {role !== Roles.GSCADMIN && (
                <AdditionalLinkText
                  additionalText="Show Recommendations"
                  id="showLastRecommendations"
                  onAdditionalTextClick={() =>
                    onShowLastRecomendationsClick("", true, true)
                  }
                />
              )}
              {shouldShowActionButtons && (
                <>
                  <Button
                    fit="large"
                    id="edit-process-impact-configuration"
                    label="Change Impact configuration"
                    name="edit-process-impact-configuration"
                    icon="cog"
                    click={handleChangeImpactConfigurationClick}
                    type="button"
                    variant="outlined"
                  />
                  <Button
                    fit="large"
                    id="add-edit-process-impact"
                    label="Add/Edit process"
                    name="add-process-impact"
                    click={() => {
                      addEditProcessImpactClick();
                    }}
                    type="button"
                    variant="filled"
                  />
                </>
              )}
            </Box>
          </Box>
        </ActionButtonsWrapper>
        {notificationsInfo()}
        <EditableProcessTable
          processes={state.processes}
          impacts={state.impacts}
          tableColumns={state.tableColumns}
          isProcessEditEnabled={isProcessEditEnabled}
          onEditCancelClick={onEditCancelClick}
          archetypeId={archeTypeId}
          isProcess={true}
          onConfigureProcessImpact={configureProcessImpact}
          onDeleteClick={onDeleteProcess}
          isReadOnly={!shouldShowActionButtons}
        />
      </>
    ) : (
      <>
        <ActionButtonsWrapper>
          <Box display="flex" p={2}>
            <FlexGrowBox />
            <Box display="flex" style={{ gap: "8px" }}>
              {shouldShowActionButtons && (
                <Button
                  fit="large"
                  id="add-new-subprocess-process"
                  label="Add new process"
                  name="add-process-impact"
                  click={() => {
                    addNewSubProcessImpactClick();
                  }}
                  type="button"
                  variant="filled"
                />
              )}
            </Box>
          </Box>
        </ActionButtonsWrapper>
        {(role == Roles.GSCADMIN || role == Roles.PROCESSOWNER) &&
          notificationsInfo()}
        {state.processes.map((process: any, index: number) => {
          return (
            <Accordian
              id={process.id}
              key={process.id}
              headerText={process.name}
              shouldShowCreateButton={shouldShowActionButtons}
              shouldShowEditButton={shouldShowActionButtons}
              shouldShowDeleteButton={
                !process.isConfiguredArchetypeLevel && shouldShowActionButtons
              }
              addButtonText="Add/Edit subprocess"
              onEdit={() =>
                handleChangeImpactConfigurationSubprocessClick(process.id)
              }
              onCreate={() => addEditSubProcessImpactClick(process.id)}
              onDelete={() =>
                onDeleteProcess(
                  process.id,
                  true,
                  process.name,
                  process.subProcessImpactMappings.length > 0
                    ? "This process has sub-processes. Deleting this process will also delete all the sub-processes."
                    : ""
                )
              }
              editButtonText="Change Impact configuration"
              editButtonIcon="cog"
              shouldShowAdditionalText={role !== Roles.GSCADMIN}
              additionalText="Show Recommendations"
              onAdditionalTextClick={() =>
                onShowLastRecomendationsClick(
                  process.name,
                  false,
                  process.isConfiguredArchetypeLevel
                )
              }
              shouldShowSwitchButton={
                role !== Roles.GSCADMIN &&
                hasAllArchetypeLevelSubprocesses(process)
              }
              onSwitchButtonClick={onSwitchClick}
              isSwitchButtonDisabled={process.isdisabled}
              shouldDisableAccordionOnSwitch={true}
              isSwitchInReadMode={!shouldShowActionButtons}
              displayWarning={process.isNew}
            >
              <EditableProcessTable
                processes={process.subProcessImpactMappings}
                impacts={state.impacts}
                tableColumns={state.tableColumns}
                isProcessEditEnabled={isSubProcessEditable[process.id]}
                onEditCancelClick={() =>
                  handleChangeImpactConfigurationSubprocessCancel(process.id)
                }
                archetypeId={archeTypeId}
                processId={process.id}
                isProcess={false}
                onConfigureProcessImpact={configureProcessImpact}
                onDeleteClick={onDeleteProcess}
                isReadOnly={!shouldShowActionButtons}
              />
            </Accordian>
          );
        })}
      </>
    );
  };

  const hasAllArchetypeLevelSubprocesses = (process: any) => {
    return process.subProcessImpactMappings.every(
      (subProcess: any) => subProcess.isConfiguredArchetypeLevel
    );
  };

  const onSwitchClick = (id: number, isEnabled: boolean) => {
    configureProcessSelection({
      processId: id,
      isdisabled: !isEnabled,
      doesSubProcessExist: false, // Intentionally passed as false as switch is on accrodion and accordion denotes a process.
    })
      .then((res) => {
        onSave();
      })
      .catch((error) => {
        toast("error", error.message, "error");
      });
  };

  if (state.loading) {
    return loadingIndicator;
  }

  const renderProcesses = () => {
    return state.processes.length > 0 ? (
      <>{mapProcesses()}</>
    ) : (
      <Welcome
        heading="Impact"
        subHeading="Start adding one or more process and sub-process."
        buttonLabel="Add process and subprocess"
        onButtonClick={addNewProcessImpactClick}
      />
    );
  };

  return (
    <>
      <AddEditProcessModal
        isOpen={state.isAddProcessImpactModalOpen}
        onSave={onSave}
        onClose={() => {
          dispatch({ type: processesActions.SET_MODAL_CLOSE });
        }}
        entityId={role === Roles.GSCADMIN ? archeTypeId : siteId}
        isTypeSwitchingAllowed={state.processes?.length === 0}
        isMappedProcessLevel={state.isProcess}
        editableProcess={state.editableProcess}
        role={role}
      />
      {role !== Roles.GSCADMIN && (
        <ProcessRecommendationsModal
          isOpen={recommendations.isRecommendationsModalOpen}
          onClose={() => showLastRecommendationsModalClose()}
          siteId={siteId}
          archetypeId={archeTypeId}
          selectedProcessName={recommendations.selectedProcessName}
          isProcess={recommendations.isProcess}
          impacts={state.impacts}
          isConfiguredArchetypeLevel={
            recommendations.isConfiguredArchetypeLevel
          }
          tableColumns={state.tableColumns}
        />
      )}
      <DeleteModal
        isOpen={state.isDeleteModalOpen}
        onSave={onSave}
        deleteEntity={state.deleteEntity}
        onClose={() => {
          dispatch({
            type: processesActions.SET_DELETE_MODAL_OPEN,
            isDeleteModalOpen: false,
          });
        }}
      />
      {!state.canAddNewProcess ? (
        <Notifications
          description={[
            "You can't add new process as there is no impacts configured by the Admin.",
          ]}
          variant={"error"}
        />
      ) : (
        renderProcesses()
      )}
    </>
  );
}

export const ActionButtonsWrapper = styled.div`
  padding: 0 0 20px 0;
`;
