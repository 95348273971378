import { useMemo, useContext } from 'react';
import { Content, PageContainer, SidebarContainer } from '../layout.styles';
import Sidebar from '@maersk-global/community-react-sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import GscAdminRoutes from '../../../routes/GscAdminRoutes';
import { UserContext } from '../layout';
import ProcessOwnerRoutes from '../../../routes/ProcessOwnerRoutes';
import SiteOwnerRoutes from '../../../routes/SiteOwnerRoutes';
import SiteAdminRoutes from '../../../routes/SiteAdminRoutes';
import SnrAdminRoutes from '../../../routes/SnrAdminRoutes';
import { Roles } from '../../../constants/Roles';

export default function SidebarLayout(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const { roles } = useContext(UserContext);

  const handleNavClick = (navUrlPath: string) => {
    if (navUrlPath === pathName) return;
    if (navUrlPath === '/') {
      window.location.href = window.location.origin;
    } else {
      navigate(navUrlPath);
    }
  };

  const findMenuItem: any = (items: Array<any>, path: string) => {
    for (let item of items) {
      if (item.path === path) {
        return item;
      } else if (item.subMenu?.length) {
        const foundItem = findMenuItem(item.subMenu, path);
        if (foundItem) {
          return foundItem;
        }
      }
    }
  };

  const getBaseRoutePathPerRole = (roles: Array<string>) => {
    if (roles.includes(Roles.GSCADMIN)) return 'hierarchy';
    if (roles.includes(Roles.PROCESSOWNER)) return 'master-data';
    if (roles.includes(Roles.BRADMIN)) return 'site-overview';
    if (roles.includes(Roles.OFFICEUSER)) return 'site-overview';
    if (roles.includes(Roles.SNRADMIN)) return 'hierarchy';
    return '/';
  };

  const getRegisteredRoutesPerRole = (roles: Array<string>) => {
    if (roles.includes(Roles.GSCADMIN)) return <GscAdminRoutes />;
    if (roles.includes(Roles.PROCESSOWNER)) return <ProcessOwnerRoutes />;
    if (roles.includes(Roles.OFFICEUSER)) return <SiteAdminRoutes />;
    if (roles.includes(Roles.SNRADMIN)) return <SnrAdminRoutes />;
    if (roles.includes(Roles.BRADMIN)) {
      return <SiteAdminRoutes />;
    }
    if (
      roles.includes(Roles.SITECOORDINATOR) ||
      roles.includes(Roles.SITEOWNER) ||
      roles.includes(Roles.SITEUSER)
    )
      return <SiteOwnerRoutes />;

    return <></>;
  };

  const memorisedRoutes = useMemo(() => getRegisteredRoutesPerRole(roles), [roles]);

  const activeNav = useMemo(() => {
    if (pathName === '/') return getBaseRoutePathPerRole(roles);
    const findMenu = findMenuItem(props.navItems, pathName);

    return findMenu?.id;
  }, [props.navItems, pathName]);

  return (
    <>
      <SidebarContainer width={90}>
        <Sidebar
          key={'sidebar_key'}
          items={props.navItems}
          activeNav={activeNav}
          isExpanded={false}
          showLogo={false}
          showLabelOnCollapse={true}
          onNavClick={handleNavClick}
          showToggleIcon={false}
          minWidth={90}
          theme='dark'
        />
      </SidebarContainer>
      <Content>
        <PageContainer>{memorisedRoutes}</PageContainer>
      </Content>
    </>
  );
}
