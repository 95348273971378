import { useEffect, useReducer, useState } from 'react';
import CommonModal from '../../../../components/common/commonModal';
import { defaultError, errorActions, errorReducer } from '../../../../reducers/error';
import useSite from '../../../../hooks/useSite';
import { saveAlternativeLocation } from '../../../../services/Site/AlternativeLocation';
import { toastSuccess } from '../../../../components/common/toast';
import {
  defaultAlternativeLocation,
  expandableSections,
  validateSiteDetails,
  validateWarehouseDetails,
  validateOthers,
  validateExternalLocation,
} from './LocationModal.utils';
import InternalLocationContent from './InternalLocationContent';
import { McRadioGroup } from '@maersk-global/mds-react-wrapper/components-core/mc-radio-group';
import { McRadio } from '@maersk-global/mds-react-wrapper/components-core/mc-radio';
import ExternalLocationContent from './ExternalLocationContent';
import { ConfigurationMapping, AlternativeLocationModel } from './LocationModal.types';

export default function AddEditAlternativeLocation({
  isOpen,
  editableAlternativeLocation,
  configurations,
  sites,
  onClose,
  onSave,
}: Readonly<{
  isOpen: boolean;
  editableAlternativeLocation: AlternativeLocationModel | null;
  configurations: ConfigurationMapping | undefined;
  sites: any;
  onClose: () => void;
  onSave: () => void;
}>): JSX.Element {
  const { site } = useSite();
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  const [expandableSection, setExpandableSection] = useState(expandableSections);
  const [alternativeLocation, setAlternativeLocation] = useState<AlternativeLocationModel>({
    ...defaultAlternativeLocation,
    siteId: site.id,
    versionId: site.versionId,
  });

  useEffect(() => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });

    if (editableAlternativeLocation != null) {
      setAlternativeLocation(editableAlternativeLocation);
    } else {
      setAlternativeLocation({
        ...defaultAlternativeLocation,
        siteId: site.id,
        versionId: site.versionId,
      });
    }

    handleInternalLocationSectionExpansion(0);
  }, [isOpen]);

  const setIsExternal = (isExternal: boolean) => {
    setAlternativeLocation({
      ...defaultAlternativeLocation,
      siteId: site.id,
      versionId: site.versionId,
      isExternal: isExternal,
    });
  };

  const handleInternalLocationSectionExpansion = (index: number) => {
    const newExpandableSections = [...expandableSection];

    newExpandableSections.forEach((section) => {
      section.isExpanded = false;
    });

    newExpandableSections[index].isExpanded = !newExpandableSections[index].isExpanded;
    setExpandableSection(newExpandableSections);
  };

  const handleInternalLocationSiteChange = (selectedSiteId: string) => {
    const selectedSite = sites.find((item: any) => item.siteId === parseInt(selectedSiteId));

    setAlternativeLocation({
      ...alternativeLocation,
      alternativeSiteId: selectedSite.siteId,
      siteCode: selectedSite.siteCode,
      siteName: selectedSite.siteName,
      siteOwner: selectedSite.siteOwner ?? '',
      address: `${selectedSite.address}`,
      fileDetails: selectedSite.fileDetails,
    });
  };

  const handleInternalLocationInputChange = (name: string, value: any) => {
    setAlternativeLocation({
      ...alternativeLocation,
      [name]: value,
    });
  };

  const handleInternalLocationClearButtonClick = (name: string) => {
    setAlternativeLocation({
      ...alternativeLocation,
      [name]: null,
    });
  };

  const handleInternalLocationValidation = () => {
    const siteErrors = validateSiteDetails(alternativeLocation);
    const warehouseErrors = validateWarehouseDetails(alternativeLocation, configurations);
    const otherErrors = validateOthers(alternativeLocation, configurations);

    return [...siteErrors, ...warehouseErrors, ...otherErrors];
  };

  const addOrUpdateLocation = () => {
    let errors: string[] = [];

    if (!alternativeLocation.isExternal) {
      errors = handleInternalLocationValidation();
    } else {
      errors = validateExternalLocation(alternativeLocation);
    }

    if (errors.length > 0) {
      dispatchErrors({ type: errorActions.ADD_ERROR, errorMessage: errors });
      return;
    }

    saveAlternativeLocation(alternativeLocation)
      .then(() => {
        toastSuccess('Alternative Location saved successfully');
        onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const onModalClose = () => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    onClose();
  };

  return (
    <CommonModal
      primaryActionLabel='Confirm'
      primaryAction={addOrUpdateLocation}
      secondaryActionLabel='Cancel'
      onSeceondaryAction={onModalClose}
      isOpen={isOpen}
      closed={onModalClose}
      heading={alternativeLocation.id === 0 ? 'Add Alternative Location' : 'Edit Alternative Location'}
      dimension='medium'
      errors={errors}
    >
      {alternativeLocation.id === 0 && <McRadioGroup
        orientation='horizontal'
        legend='Location Type'
        style={{ marginBottom: '1rem' }}
        name='locationtype'
        value={alternativeLocation.isExternal ? 'External' : 'Internal'}
      >
        <McRadio
          name='locationtype'
          label='Internal'
          value={'Internal'}
          checked={!alternativeLocation.isExternal}
          onClick={() => {
            setIsExternal(false);
          }}
        />
        <McRadio
          name='locationtype'
          label='External'
          value={'External'}
          checked={alternativeLocation.isExternal}
          onClick={() => {
            setIsExternal(true);
          }}
        />
      </McRadioGroup>}
      {!alternativeLocation.isExternal ? (
        <InternalLocationContent
          configurations={configurations}
          editableAlternateLocation={alternativeLocation}
          expandableSection={expandableSection}
          handleSectionExpansion={handleInternalLocationSectionExpansion}
          handleInputChange={handleInternalLocationInputChange}
          handleClearButtonClick={handleInternalLocationClearButtonClick}
          handleSiteChange={handleInternalLocationSiteChange}
          sites={sites}
        />
      ) : (
        <ExternalLocationContent
          externalLocation={alternativeLocation}
          setExternalLocation={setAlternativeLocation}
        />
      )}
    </CommonModal>
  );
}
