import React, { useEffect, useReducer, useState } from "react";
import CommonModal from "../../../../components/common/commonModal";
import ConfigurableComponentsRenderer from "../../../../components/common/configurableItemsRenderer";
import { configuration } from "../../../../constants/Site";
import {
  McInput,
  McRadioGroup,
  McRadio,
} from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../../reducers/error";
import useSite from "../../../../hooks/useSite";
import { toastSuccess } from "../../../../components/common/toast";
import {
  ExternalContactsKeys,
  SaveExternalContactsModel,
} from "../../../../models/ExternalContacts";
import { saveExternalContact } from "../../../../services/Site/ExternalContact";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../../utils/validation-utils";
import { trimStringsBeforeSave } from "../../../../utils/utils";
import SiteMandatoryLabel from "../../../../components/common/siteMandatoryLabel";
import { ConfigurationMapping } from "../../AlternativeLocation/modals/LocationModal.types";

const defaultExternalContacts: SaveExternalContactsModel = {
  siteId: 0,
  versionId: 0,
  id: 0,
  serviceName: "",
  supplierName: "",
  isCriticalSupplier: false,
  primaryContactName: "",
  primaryContactEmail: "",
  primaryContactPhone: "",
  secondaryContactName: "",
  secondaryContactEmail: "",
  secondaryContactPhone: "",
  isConfiguredArchetypeLevel: false,
};

export default function AddEditExternalContact({
  isOpen,
  editableExternalContact,
  configurations,
  keys,
  onClose,
  onSave,
}: Readonly<{
  isOpen: boolean;
  editableExternalContact: any;
  configurations: ConfigurationMapping | undefined;
  keys: ExternalContactsKeys;
  onClose: () => void;
  onSave: () => void;
}>) {
  const { site } = useSite();
  const [editableExternalContacts, setEditableExternalContacts] =
    useState<SaveExternalContactsModel>(defaultExternalContacts);
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);

  useEffect(() => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    if (editableExternalContact != null) {
      setEditableExternalContacts(editableExternalContact);
    } else {
      setEditableExternalContacts({
        ...defaultExternalContacts,
        siteId: site.id,
        versionId: site.versionId,
      });
    }
  }, [isOpen]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEditableExternalContacts({
      ...editableExternalContacts,
      [name]: value,
    });
  };

  const handleCheckboxChange = (name: string, value: boolean) => {
    setEditableExternalContacts({
      ...editableExternalContacts,
      [name]: value,
    });
  };

  const handleClearButtonClick = (name: string) => {
    setEditableExternalContacts({
      ...editableExternalContacts,
      [name]: "",
    });
  };

  const onModalClose = () => {
    onClose();
  };

  const isMandatory = (key: string): boolean => {
    if (!configurations) return false;
    return configurations[key] === configuration.Mandatory;
  };

  const validateInternalContacts = () => {
    const errors: string[] = [];
    let {
      serviceName,
      supplierName,
      primaryContactName,
      primaryContactEmail,
      primaryContactPhone,
      secondaryContactName,
      secondaryContactEmail,
      secondaryContactPhone,
    } = editableExternalContacts;

    if (isMandatory(keys.service) && !serviceName?.trim()) {
      errors.push("Service is required");
    }
    if (isMandatory(keys.supplierName) && !supplierName?.trim()) {
      errors.push("Supplier Name is required");
    }
    if (isMandatory(keys.primaryContactName) && !primaryContactName?.trim()) {
      errors.push("Primary Contact Name is required");
    }
    if (isMandatory(keys.primaryContactEmail) && !primaryContactEmail?.trim()) {
      errors.push("Primary Contact Email is required");
    }
    if (isMandatory(keys.primaryContactPhone) && !primaryContactPhone?.trim()) {
      errors.push("Primary Contact Phone is required");
    }
    if (isMandatory(keys.secondaryContactName) && !secondaryContactName?.trim) {
      errors.push("Secondary Contact Name is required");
    }
    if (
      isMandatory(keys.secondaryContactEmail) &&
      !secondaryContactEmail?.trim()
    ) {
      errors.push("Secondary Contact Email is required");
    }
    if (
      isMandatory(keys.secondaryContactPhone) &&
      !secondaryContactPhone?.trim()
    ) {
      errors.push("Secondary Contact Phone is required");
    }

    if (
      primaryContactPhone &&
      !validatePhoneNumber(primaryContactPhone.trim())
    ) {
      errors.push("Primary Contact Phone is invalid");
    }
    if (
      secondaryContactPhone &&
      !validatePhoneNumber(secondaryContactPhone?.trim())
    ) {
      errors.push("Secondary Contact Phone is invalid");
    }
    if (primaryContactEmail && !validateEmail(primaryContactEmail?.trim())) {
      errors.push("Primary Contact Email is invalid");
    }
    if (
      secondaryContactEmail &&
      !validateEmail(secondaryContactEmail?.trim())
    ) {
      errors.push("Secondary Contact Email is invalid");
    }
    return errors;
  };

  const addOrUpdateExternalContacts = () => {
    const errors = validateInternalContacts();
    if (errors.length > 0) {
      dispatchErrors({ type: errorActions.ADD_ERROR, errorMessage: errors });
      return;
    }

    saveExternalContact(trimStringsBeforeSave(editableExternalContacts))
      .then((response) => {
        toastSuccess("External Contact saved successfully");
        onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  return (
    <div>
      <CommonModal
        primaryActionLabel="Confirm"
        primaryAction={addOrUpdateExternalContacts}
        secondaryActionLabel="Cancel"
        onSeceondaryAction={onModalClose}
        isOpen={isOpen}
        onModalClose={onModalClose}
        heading={"Add/Edit External Contact"}
        dimension="medium"
        errors={errors}
      >
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.service}
        >
          <McInput
            label=""
            fit="small"
            value={editableExternalContacts.serviceName}
            input={(e: any) => handleInputChange(e)}
            name="serviceName"
            disabled={editableExternalContacts.isConfiguredArchetypeLevel}
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Description"
              isMandatory={isMandatory(keys.service)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.supplierName}
        >
          <McInput
            label=""
            fit="small"
            value={editableExternalContacts.supplierName}
            input={(e: any) => handleInputChange(e)}
            name="supplierName"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Supplier Name"
              isMandatory={isMandatory(keys.supplierName)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <RadioGroupWrapper>
          <McRadioGroup
            legend="Critical Supplier"
            name="isPrimary"
            orientation="horizontal"
          >
            <McRadio
              name="isCriticalSupplier"
              label="Yes"
              value={"true"}
              checked={editableExternalContacts.isCriticalSupplier}
              onClick={(e: any) => handleCheckboxChange(e.target.name, true)}
            />
            <McRadio
              name="isCriticalSupplier"
              label="No"
              value={"false"}
              checked={!editableExternalContacts.isCriticalSupplier}
              onClick={(e: any) => handleCheckboxChange(e.target.name, false)}
            />
          </McRadioGroup>
        </RadioGroupWrapper>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.primaryContactName}
        >
          <McInput
            label=""
            fit="small"
            value={editableExternalContacts.primaryContactName}
            input={(e: any) => handleInputChange(e)}
            name="primaryContactName"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Primary Contact Name"
              isMandatory={isMandatory(keys.primaryContactName)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.primaryContactEmail}
        >
          <McInput
            label=""
            fit="small"
            value={editableExternalContacts.primaryContactEmail}
            input={(e: any) => handleInputChange(e)}
            name="primaryContactEmail"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Primary Contact Email"
              isMandatory={isMandatory(keys.primaryContactEmail)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.primaryContactPhone}
        >
          <McInput
            label="Primary Contact Phone"
            fit="small"
            value={editableExternalContacts.primaryContactPhone}
            input={(e: any) => handleInputChange(e)}
            name="primaryContactPhone"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Primary Contact Phone"
              isMandatory={isMandatory(keys.primaryContactPhone)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.secondaryContactName}
        >
          <McInput
            label=""
            fit="small"
            value={editableExternalContacts.secondaryContactName}
            input={(e: any) => handleInputChange(e)}
            name="secondaryContactName"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Secondary Contact Name"
              isMandatory={isMandatory(keys.secondaryContactName)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.secondaryContactEmail}
        >
          <McInput
            label=""
            fit="small"
            value={editableExternalContacts.secondaryContactEmail}
            input={(e: any) => handleInputChange(e)}
            name="secondaryContactEmail"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Secondary Contact Email"
              isMandatory={isMandatory(keys.secondaryContactEmail)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
        <ConfigurableComponentsRenderer
          configurations={configurations}
          configKey={keys.secondaryContactPhone}
        >
          <McInput
            label="Secondary Contact Phone"
            fit="small"
            value={editableExternalContacts.secondaryContactPhone}
            input={(e: any) => handleInputChange(e)}
            name="secondaryContactPhone"
            maxlength={100}
          >
            <SiteMandatoryLabel
              slot="label"
              label="Secondary Contact Phone"
              isMandatory={isMandatory(keys.secondaryContactPhone)}
            />
          </McInput>
        </ConfigurableComponentsRenderer>
      </CommonModal>
    </div>
  );
}

const RadioGroupWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
